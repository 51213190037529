import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'hasType'
})
export class HasTypePipe implements PipeTransform {

  transform(value: unknown) {
    let castedValue: any;
    if (typeof value === 'string' || (typeof value === 'object' && !Array.isArray(value))){
      castedValue = value;
    } else if (Array.isArray(value)) {
      castedValue = value
    }
    return castedValue;
  }

}
