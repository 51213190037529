export class MessageContainer {
    private sessionId: string;
    private oobid: string;
    private taskResponse: {[key: string]: any};
    private messages: {[key: string]: any}[];
    private type?: string;

    constructor(sessionID: string, oobid: string, taskType: string, taskAmount: number, topLevelType: string = "") {
        this.sessionId = sessionID;
        this.oobid = oobid;
        if (topLevelType.length > 0) {
            this.type = topLevelType;
        };
        this.taskResponse = {
            "type": "task.res",
            "oobid": this.oobid,
            "task": {
                "type": taskType,
                "amount": taskAmount
            }
        }
        this.messages = [];
    }

    getSessionId(){
        return this.sessionId;
    }

    getOobid(){
        return this.oobid;
    }

    getMessages() {
        return this.messages;
    }

    setMessageObject(messageObject: {[key: string]: any}) {
        this.messages.push(messageObject);
    }

    getTask() {
        return this.taskResponse;
    }

    setTaskAmount(amount: number) {
        this.taskResponse['task']['amount'] = amount;
    }

    getTaskAmount() {
        return this.taskResponse['task']['amount'];
    }
}
