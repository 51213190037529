
<header>{{currentParty}}</header>
<div class="main-wrapper container-fluid">
  <div class="col-xl-6 col-lg-6 col-md-8 col-sm-10 mx-auto p-3 pt-3">
  <div class="card border-0 card_shadow">
    <div class="card-body">
        <p id="description">{{placeholderString}} besucht.</p>
    </div>
  </div>
  </div>
  <div class="flex-wrapper">
    <button type="submit" (click)="onFinalSubmit()" class="link-box-white">Nachweis Abholen</button>
    <button (click)="onClickBack()" class="link-box-white">Zurück</button>
  </div>
</div>

<dialog id="dialog-box">
    <h3>Möchten Sie den Vorgang beenden und zur Standauswahl zurückkehren?</h3>
    <div class="inline">
        <button (click)="onDialogCancel()" class="link-box-white">Nein</button>
        <button (click)="onDialogOk()" class="link-box-white">Ja</button>
    </div>
</dialog>

<app-showqrcode *ngIf="showQRCode" [verificationUrl]="appUrl" [isRequestPresentation]="false"></app-showqrcode>
<ng-container *ngFor="let message of messageArray">
  <app-toaster *ngIf="message.length > 0" [messageText]="message" [status]="statusOk"></app-toaster>
</ng-container>
