import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getLang'
})
export class GetLangPipe implements PipeTransform {

  langObject: {[key: string]: string} = {
    honorificPrefix: "Anrede",
    firstName: "Vorname",
    lastName: "Nachname",
    birthName: "Geburtsname",
    streetName: "Straßenname",
    houseNumber: "Hausnummer",
    addressLocality: "Ort",
    postalCode: "Postleitzahl",
    residentSince: "wohnhaft seit",
    birthDate: "Geburtsdatum",
    birthPlace: "Geburtsort",
    gender: "Geschlecht",
    parent: "Elternteil",
    sameAs: "entspricht (URI des Credentials)",
    termCode: "Gesetzesgrundlage",
    inDefinedTermSet: "Link zur Gesetzesgrundlage",
    meetsRequirement: "Erfüllt Nachweis",
    keywords: "International anwendbares Recht",
    image: "Bild",
    required: "Eingaben sind erforderlich",
    givenName: "Vorname",
    familyName: "Nachname",
    parent1: "Elternteil",
    iban: "IBAN",
    bic: "BIC",
    creditInstitution: "Kreditinstitut",
    repeatablePayment: "wiederkehrende Zahlung",
    intendedUse: "Verwendungszweck",
    true: "Ja",
    false: "Nein",
    KDKAgeProofK: "Altersnachweis",
    KDKBiometricPhotoK: "Biometrisches Foto",
    KDKPersonK: "Personennachweis",
    KDKAddressK: "Adressnachweis",
    KDKBirthCertificateK: "Geburtsurkundennachweis",
    KommPassAdresse: 'KommPass Adresse',
    KommPassGeburtsurkunde: 'KommPass Geburtsurkunde',
    KommPassPerson: 'KommPass Person',
    KommPassAltersnachweis: 'KommPass Altersnachweis',
    KommPassLichtbild: 'KommPass Lichtbild',
    DrivingLicense: "Fahrerlaubnis",
    SEPADirectDebitMandate: "SEPA-Lastschrifteinzug",
    encodingFormat: "Bildformat",
    MobilePhoneContract: "Mobilfunkvertrag",
    BADA: "Bankverfügungs-Credential",
    id: "ID",
    img: "Bild",
    isProtectedByCRE: "Hardwareverschlüsselt",
    provisionalHolder: "vorläufiger Inhaber",
    male: "männlich",
    female: "weiblich",
    Male: "männlich",
    Female: "weiblich",
    Jungheinrich: "Jungheinrich-Nutzungsvertrag",
    teilAuto: "teilAuto-Nutzungsvertrag",
    Nextbike: "Nextbike-Nutzungsvertrag",
    "Mrs.": "Frau",
    "Mr.": "Herr",
    blz: "BLZ",
    brand: "Accountbezeichnung",
    1: "Mini",
    2: "Comfort",
    3: "Premium",

  }
  
  transform(value: any): string {
    if (this.langObject.hasOwnProperty(value.toString())) {
      return this.langObject[value];
    } else if (this.langObject.hasOwnProperty(value)) {
      return this.langObject[value];
    } else {
      return value;
    }
  }

}
