<header>Privacy Policy &mdash; {{headerString}}</header>

<div class="main-wrapper container-fluid" *ngIf="isIdIdeal">
  <div class="col-xl-6 col-lg-6 col-md-8 col-sm-10 mx-auto p-3 pt-3">
    <div class="card border-0 card_shadow">
      <div class="card-body">
        <p>KAPRION Technologies GmbH built the Ideal Wallet Beta app as a Free app. This SERVICE is provided by KAPRION
          Technologies GmbH at no cost and is intended for use as is.</p>
        <p>This page is used to inform visitors regarding our policies with the collection, use, and disclosure of
          Personal Information if anyone decided to use our Service.</p>
        <p>If you choose to use our Service, then you agree to the collection and use of information in relation to this
          policy. The Personal Information that we collect is used for providing and improving the Service. We will not
          use or share your information with anyone except as described in this Privacy Policy.</p>
        <p>The terms used in this Privacy Policy have the same meanings as in our Terms and Conditions, which are
          accessible at Ideal Wallet Beta unless otherwise defined in this Privacy Policy.</p>
        <p><strong>Information Collection and Use</strong></p>
        <p>For a better experience, while using our Service, we may require you to provide us with certain personally
          identifiable information, including but not limited to none. The information that we request will be retained
          by us and used as described in this privacy policy.</p>
        <p>The app does use third-party services that may collect information used to identify you.</p>
        <p>Link to the privacy policy of third-party service providers used by the app</p>
        <ul>
          <li><a rel="nofollow noreferrer noopener" href="https://www.google.com/policies/privacy/"
              target="_blank">Google Play Services</a></li>
        </ul>
        <p><strong>Log Data</strong></p>
        <p>We want to inform you that whenever you use our Service, in a case of an error in the app we collect data and
          information (through third-party products) on your phone called Log Data. This Log Data may include
          information such as your device Internet Protocol (“IP”) address, device name, operating system version, the
          configuration of the app when utilizing our Service, the time and date of your use of the Service, and other
          statistics.</p>
        <p><strong>Cookies</strong></p>
        <p>Cookies are files with a small amount of data that are commonly used as anonymous unique identifiers. These
          are sent to your browser from the websites that you visit and are stored on your device's internal memory.</p>
        <p>This Service does not use these “cookies” explicitly. However, the app may use third-party code and libraries
          that use “cookies” to collect information and improve their services. You have the option to either accept or
          refuse these cookies and know when a cookie is being sent to your device. If you choose to refuse our cookies,
          you may not be able to use some portions of this Service.</p>
        <p><strong>Service Providers</strong></p>
        <p>We may employ third-party companies and individuals due to the following reasons:</p>
        <ul>
          <li>To facilitate our Service;</li>
          <li>To provide the Service on our behalf;</li>
          <li>To perform Service-related services; or</li>
          <li>To assist us in analyzing how our Service is used.</li>
        </ul>
        <p>We want to inform users of this Service that these third parties have access to their Personal Information.
          The reason is to perform the tasks assigned to them on our behalf. However, they are obligated not to disclose
          or use the information for any other purpose.</p>
        <p><strong>Security</strong></p>
        <p>We value your trust in providing us your Personal Information, thus we are striving to use commercially
          acceptable means of protecting it. But remember that no method of transmission over the internet, or method of
          electronic storage is 100% secure and reliable, and we cannot guarantee its absolute security.</p>
        <p><strong>Links to Other Sites</strong></p>
        <p>This Service may contain links to other sites. If you click on a third-party link, you will be directed to
          that site. Note that these external sites are not operated by us. Therefore, we strongly advise you to review
          the Privacy Policy of these websites. We have no control over and assume no responsibility for the content,
          privacy policies, or practices of any third-party sites or services.</p>
        <p><strong>Children’s Privacy</strong></p>
        <p>These Services do not address anyone under the age of 13. We do not knowingly collect personally identifiable
          information from children under 13 years of age. In the case we discover that a child under 13 has provided us
          with personal information, we immediately delete this from our servers. If you are a parent or guardian and
          you are aware that your child has provided us with personal information, please contact us so that we will be
          able to do the necessary actions.</p>
        <p><strong>Changes to This Privacy Policy</strong></p>
        <p>We may update our Privacy Policy from time to time. Thus, you are advised to review this page periodically
          for any changes. We will notify you of any changes by posting the new Privacy Policy on this page.</p>
        <p>This policy is effective as of 2023-10-20</p>
        <p><strong>Contact Us</strong></p>
        <p>If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us at <a
            href="mailto:vertrieb@kaprion.de">vertrieb@kaprion.de</a>.</p>
        <p>This privacy policy page was created at <a rel="nofollow noreferrer noopener"
            href="https://privacypolicytemplate.net" target="_blank">privacypolicytemplate.net</a> and
          modified/generated by <a rel="nofollow noreferrer noopener"
            href="https://app-privacy-policy-generator.nisrulz.com/" target="_blank">App Privacy Policy Generator</a>
        </p>
      </div>
    </div>
  </div>
</div>

<div class="main-wrapper container-fluid" *ngIf="lang === 'en'">
  <div class="col-xl-6 col-lg-6 col-md-8 col-sm-10 mx-auto p-3 pt-3">
    <div class="card border-0 card_shadow">
      <div class="card-body">
        <p>KAPRION Technologies GmbH has developed the ticketXchange app to enable the issue and withdrawal of travel
          authorisations on smartcards. This SERVICE is provided by KAPRION Technologies GmbH free of charge for the
          cardholder and is intended for use in the actual state.</p>
        <p>This page is used to inform visitors regarding our policies with the collection, use, and disclosure of
          Personal Information if anyone decided to use our Service.</p>
        <p>If you choose to use our Service, then you agree to the collection and use of information in relation to this
          policy. The Personal Information that we collect is used for providing and improving the Service. We will not
          use or share your information with anyone except as described in this Privacy Policy.</p>
        <p>Unless otherwise defined in this Privacy Policy, terms used in this Privacy Policy have the same meanings as
          in our Terms and Conditions, which can be found at <a rel="nofollow noreferrer noopener" href="www.kaprion.de"
            target="_blank">www.kaprion.de</a>.</p>
        <p><strong>Information Collection and Use</strong></p>
        <p>To facilitate your use of our Service, we may ask you to provide us with certain personal information,
          including, but not limited to, information about the type of device and its manufacturer with which you use
          the Service. The information we request will be stored by us and used as described in this Privacy Policy.</p>
        <p><strong>Log Data</strong></p>
        <p>We want to inform you that whenever you use our Service, in a case of an error in the app we collect data and
          information (through third-party products) on your phone called Log Data. This Log Data may include
          information such as your device Internet Protocol (“IP”) address, device name, operating system version, the
          configuration of the app when utilizing our Service, the time and date of your use of the Service, and other
          statistics.</p>
        <p><strong>Cookies</strong></p>
        <p>Cookies are files with a small amount of data that are commonly used as anonymous unique identifiers. These
          are sent to your browser from the websites that you visit and are stored on your device's internal memory.</p>
        <p>This Service does not use these “cookies” explicitly. However, the app may use third-party code and libraries
          that use “cookies” to collect information and improve their services. You have the option to either accept or
          refuse these cookies and know when a cookie is being sent to your device. If you choose to refuse our cookies,
          you may not be able to use some portions of this Service.</p>
        <p><strong>Service Providers</strong></p>
        <p>We may employ third-party companies and individuals due to the following reasons:</p>
        <ul>
          <li>To facilitate our Service;</li>
          <li>To provide the Service on our behalf;</li>
          <li>To perform Service-related services; or</li>
          <li>To assist us in analyzing how our Service is used.</li>
        </ul>
        <p>We want to inform users of this Service that these third parties have access to their Personal Information.
          The reason is to perform the tasks assigned to them on our behalf. However, they are obligated not to disclose
          or use the information for any other purpose.</p>
        <p><strong>Security</strong></p>
        <p>We value your trust in providing us your Personal Information, thus we are striving to use commercially
          acceptable means of protecting it. But remember that no method of transmission over the internet, or method of
          electronic storage is 100% secure and reliable, and we cannot guarantee its absolute security.</p>
        <p><strong>Links to Other Sites</strong></p>
        <p>This Service may contain links to other sites. If you click on a third-party link, you will be directed to
          that site. Note that these external sites are not operated by us. Therefore, we strongly advise you to review
          the Privacy Policy of these websites. We have no control over and assume no responsibility for the content,
          privacy policies, or practices of any third-party sites or services.</p>
        <p><strong>Children’s Privacy</strong></p>
        <p>These Services do not address anyone under the age of 13. We do not knowingly collect personally identifiable
          information from children under 13 years of age. In the case we discover that a child under 13 has provided us
          with personal information, we immediately delete this from our servers. If you are a parent or guardian and
          you are aware that your child has provided us with personal information, please contact us so that we will be
          able to do the necessary actions.</p>
        <p><strong>Changes to This Privacy Policy</strong></p>
        <p>We may update our Privacy Policy from time to time. Thus, you are advised to review this page periodically
          for any changes. We will notify you of any changes by posting the new Privacy Policy on this page.</p>
        <p>This policy is effective as of 2024-01-24</p>
        <p><strong>Contact Us</strong></p>
        <p>If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us at <a
            href="mailto:vertrieb@kaprion.de">vertrieb@kaprion.de</a>.</p>
        <p>This privacy policy page was created at <a rel="nofollow noreferrer noopener"
            href="https://privacypolicytemplate.net" target="_blank">privacypolicytemplate.net</a> and
          modified/generated by <a rel="nofollow noreferrer noopener"
            href="https://app-privacy-policy-generator.nisrulz.com/" target="_blank">App Privacy Policy Generator</a>
        </p>
      </div>
    </div>
  </div>
</div>

  <div class="main-wrapper container-fluid" *ngIf="lang === 'de'">
    <div class="col-xl-6 col-lg-6 col-md-8 col-sm-10 mx-auto p-3 pt-3">
      <div class="card border-0 card_shadow">
        <div class="card-body">
          <p>Die KAPRION Technologies GmbH hat die ticketXchange-App entwickelt, um die Ausgabe und Rücknahme von
            Fahrberechtigungen auf Smartcards zu ermöglichen. Dieser SERVICE wird von der KAPRION Technologies GmbH für
            den Karteninhaber kostenlos zur Verfügung gestellt und ist für die Nutzung im Ist-Zustand gedacht.</p>
          <p>Diese Seite dient dazu, Besucher über unsere Richtlinien bezüglich der Sammlung, Verwendung und Offenlegung
            von persönlichen Informationen zu informieren.</p>
          <p>Wenn Sie sich entscheiden, unseren Service zu nutzen, stimmen Sie der Sammlung und Nutzung von
            Informationen in Bezug auf diese Richtlinie zu. Die von uns gesammelten persönlichen Daten werden für die
            Bereitstellung und Verbesserung des Dienstes verwendet. Wir werden Ihre Daten nur wie in dieser
            Datenschutzrichtlinie beschrieben verwenden oder weitergeben.</p>
          <p>Sofern in dieser Datenschutzrichtlinie nicht anders definiert, haben die in dieser Datenschutzrichtlinie
            verwendeten Begriffe dieselbe Bedeutung wie in unseren Allgemeinen Geschäftsbedingungen, die unter <a
              rel="nofollow noreferrer noopener" href="www.kaprion.de" target="_blank">www.kaprion.de</a> eingesehen
            werden können.</p>
          <p><strong>Sammlung und Verwendung von Informationen</strong></p>
          <p>Um Ihnen die Nutzung unseres Dienstes zu erleichtern, bitten wir Sie möglicherweise, uns bestimmte
            personenbezogene Daten mitzuteilen, unter anderem, aber nicht ausschließlich, den Informationen über den Typ
            des Geräts und dessen Hersteller, mit dem sie den Dienst nutzen. Die von uns angeforderten Informationen
            werden von uns gespeichert und wie in dieser Datenschutzrichtlinie beschrieben verwendet.</p>
          <p><strong>Log-Daten</strong></p>
          <p>Wir möchten Sie darüber informieren, dass wir bei der Nutzung unseres Dienstes im Falle eines Fehlers in
            der App Daten und Informationen (durch Produkte von Drittanbietern) auf Ihrem Telefon sammeln, die
            sogenannten Protokolldaten. Diese Protokolldaten können Informationen wie die Internetprotokoll-Adresse
            ("IP") Ihres Geräts, den Gerätenamen, die Version des Betriebssystems, die Konfiguration der App bei der
            Nutzung unseres Dienstes, die Uhrzeit und das Datum Ihrer Nutzung des Dienstes sowie andere Statistiken
            enthalten.</p>
          <p><strong>Cookies</strong></p>
          <p>Cookies sind Dateien mit einer kleinen Menge an Daten, die üblicherweise als anonyme eindeutige
            Identifikatoren verwendet werden. Sie werden von den Websites, die Sie besuchen, an Ihren Browser gesendet
            und im internen Speicher Ihres Geräts gespeichert.</p>
          <p>Dieser Dienst verwendet diese "Cookies" ausdrücklich nicht. Die App kann jedoch Code und Bibliotheken von
            Drittanbietern verwenden, die "Cookies" nutzen, um Informationen zu sammeln und ihre Dienste zu verbessern.
            Sie haben die Möglichkeit, diese Cookies entweder zu akzeptieren oder abzulehnen und zu erfahren, wann ein
            Cookie an Ihr Gerät gesendet wird. Wenn Sie sich entscheiden, unsere Cookies abzulehnen, können Sie
            möglicherweise einige Teile dieses Dienstes nicht nutzen.</p>
          <p><strong>Dienstanbieter</strong></p>
          <p>Wir können aus den folgenden Gründen Drittunternehmen und Einzelpersonen beauftragen:</p>
          <ul>
            <li>um unseren Dienst zu erleichtern;</li>
            <li>zur Bereitstellung des Dienstes in unserem Namen;</li>
            <li>um dienstbezogene Dienstleistungen zu erbringen; oder</li>
            <li>um uns bei der Analyse der Nutzung unseres Dienstes zu unterstützen.</li>
          </ul>
          <p>Wir möchten die Nutzer dieses Dienstes darüber informieren, dass diese Dritten Zugang zu ihren persönlichen
            Daten haben. Der Grund dafür ist, dass sie die ihnen übertragenen Aufgaben in unserem Namen ausführen. Sie
            sind jedoch verpflichtet, die Informationen nicht weiterzugeben oder für andere Zwecke zu verwenden.</p>
          <p><strong>Sicherheit</strong></p>
          <p>Wir wissen Ihr Vertrauen zu schätzen, das Sie uns bei der Übermittlung Ihrer persönlichen Daten
            entgegenbringen, und bemühen uns daher um den Einsatz kommerziell akzeptabler Mittel zu deren Schutz. Denken
            Sie jedoch daran, dass keine Methode der Übertragung über das Internet oder der elektronischen Speicherung
            zu 100 % sicher und zuverlässig ist, und wir können keine absolute Sicherheit garantieren.</p>
          <p><strong>Links zu anderen Seiten</strong></p>
          <p>Dieser Dienst kann Links zu anderen Websites enthalten. Wenn Sie auf einen Link eines Dritten klicken,
            werden Sie zu dieser Site weitergeleitet. Beachten Sie, dass diese externen Sites nicht von uns betrieben
            werden. Wir empfehlen Ihnen daher dringend, die Datenschutzrichtlinien dieser Websites zu lesen. Wir haben
            keine Kontrolle über und übernehmen keine Verantwortung für den Inhalt, die Datenschutzrichtlinien oder die
            Praktiken von Websites oder Diensten Dritter.</p>
          <p><strong>Datenschutz für Kinder</strong></p>
          <p>Dieser Dienst richtet sich nicht an Personen unter 13 Jahren. Wir sammeln nicht wissentlich
            personenbezogene Daten von Kindern unter 13 Jahren. Sollten wir feststellen, dass ein Kind unter 13 Jahren
            uns persönliche Daten zur Verfügung gestellt hat, löschen wir diese sofort von unseren Servern. Wenn Sie ein
            Elternteil oder Erziehungsberechtigter sind und wissen, dass Ihr Kind uns personenbezogene Daten zur
            Verfügung gestellt hat, setzen Sie sich bitte mit uns in Verbindung, damit wir die erforderlichen Maßnahmen
            ergreifen können.</p>
          <p><strong>Änderungen an dieser Datenschutzrichtlinie</strong></p>
          <p>Wir können unsere Datenschutzrichtlinie von Zeit zu Zeit aktualisieren. Wir empfehlen Ihnen daher, diese
            Seite regelmäßig auf Änderungen zu überprüfen. Wir werden Sie über alle Änderungen informieren, indem wir
            die neue Datenschutzrichtlinie auf dieser Seite veröffentlichen.</p>
          <p>Diese Richtlinie gilt ab dem 24.01.2024</p>
          <p><strong>Kontaktieren Sie uns</strong></p>
          <p>Wenn Sie Fragen oder Anregungen zu unserer Datenschutzrichtlinie haben, zögern Sie nicht, uns unter <a
              href="mailto:vertrieb@kaprion.de">vertrieb@kaprion.de</a> zu kontaktieren.</p>
          <p>Diese Seite mit den Datenschutzrichtlinien wurde auf <a rel="nofollow noreferrer noopener"
              href="https://privacypolicytemplate.net" target="_blank">privacypolicytemplate.net</a> erstellt und von <a
              rel="nofollow noreferrer noopener" href="https://app-privacy-policy-generator.nisrulz.com/"
              target="_blank">App Privacy Policy Generator</a> modifiziert/generiert.</p>
        </div>
      </div>
    </div>


    <div class="flex-wrapper">
      <button (click)="onClickBack()" class="link-box-white">Zurück</button>
    </div>

  </div>