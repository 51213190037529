<header class="heading">Abschluss eines Mobilfunkvertrages</header>
<div class="main-wrapper container-fluid" style="display: block;">
  <div *ngIf="!statusOk && !showQrCode && (steps === 0)"
    class="col-xl-6 col-lg-6 col-md-8 col-sm-10 mx-auto p-3 pt-3">
    <span class="sub-header sub-header-with-margin">Handytarife</span>
    <!-- <div class="card border-0 card_shadow" *ngIf="false">
      <div class="card-body">

        <p>Wir freuen uns, dass Sie einen Telefonvertrag bei uns eröffnen möchten.</p>
        <p>Die folgenden Schritte werden durchgeführt:</p>
        <p>Wenn Sie den Prozess starten wird Ihnen ein QR-Code angezeigt. Scannen Sie diesen bitte mit Ihrer IDeal
          Wallet App. Sie werden im Anschluss gebeten, die folgenden drei Nachweise zu präsentieren:</p>
        <ol>
          <li>KommPass Name</li>
          <li>KommPass Adresse</li>
          <li>KommPass Geburtsurkunde</li>
          <li>BADA (Bankkonto-Verfügungsberechtigungs-Credential).</li>

        </ol>
        <p>Diese Informationen benötigen wir um einen Mobilfunkvertrag mit Ihnen abschließen zu dürfen</p>
        <p>Wir werden diese Daten für die Dauer unserer gemeinsamen Geschäftsbeziehung sicher verwahren.</p>
        <p>Mit der Eröffnung Ihres Mobilfunkvertrages stellen wir Ihnen ein Mobilfunknachweis aus.</p>
        <p>Mit diesem können Sie Dritten gegenüber nachweisen, dass Sie Verfügungsberechtigter über dies Telefonnummer
          sind.</p>
      </div>
    </div> -->
    <div class="rectangle-container">
      <div *ngFor="let i of [1,2,3]" class="rectangle">
        <i *ngIf="i===1" class="fa-solid fa-seedling"></i>
        <i *ngIf="i===2" class="fa-solid fa-gauge"></i>
        <i *ngIf="i===3" class="fa-solid fa-gauge-high"></i>
        <div class="text-box">
          <h4>{{i | getLang}}</h4>
          <h5>{{5*i}} GB</h5>
          <h3>{{5*2*i-0.01}} EUR</h3>
          <p>Flatrate Telefonie</p>
          <p>Flatrate Internet mit {{5*i}} GB im Monat</p>
          <p>Flatrate SMS</p>
        </div>
        <button class="rectangle-button link-box-white" (click)="selectTarif(i)">Auswählen</button>
      </div>
    </div>
  </div>
  <div *ngIf="!statusOk && !showQrCode && (steps === 1)">
    <div class="row justify-content-center">
      <div class="col-xl-6 col-lg-6 col-md-8 col-sm-10 mx-auto p-3 pt-3">
        <span class="sub-header">Warenkorb</span>
        <div class="card border-0 card_shadow">
          <div class="card-body">
    
            <div class="mobile-contract-type">
              <div class="mobile-contract-info">
                <span class="mobile-contract-info-content">{{'Tarif: ' + credSubject.brand}}</span>
                <!--<span class="mobile-contract-info-content">{{'Datenvolumen: ' + credSubject.datavolume}}</span>-->
                <span class="mobile-contract-info-content">{{'Preis: ' + credSubject.price + '€'}}</span>
                <span class="mobile-contract-info-content">{{'Handynummer: ' + credSubject.phoneNumber}}</span>
              </div>
              <div (click)="removeFromBasket()" class="mobile-contract-delete">
                <img [src]="imgURL + 'delete-icon.png'" class="mobile-contract-delete-icon">
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="!statusOk && !showQrCode && (steps === 2)"
    class="col-xl-6 col-lg-6 col-md-8 col-sm-10 mx-auto p-3 pt-3">
    <div class="card border-0 card_shadow card-fade">
      <div class="card-body">

        <p>Folgende Nachweise werden abgefragt:</p>
        <ol>
          <li>KommPass Person</li>
          <li>KommPass Geburtsurkunde</li>
          <li>KommPass Adresse</li>
          <li>BADA.</li>
        </ol>
        <img>
        <p>Bitte bestätigen Sie die Präsentation dieser Nachweise</p>
        <!-- <div class="image-container">
          <img [src]="imgURL + 'A53+IDeal WALLET_ReviewPresentationRequest.png'" class="portrait">
        </div>
        <i class="fa-solid fa-arrow-left" id="request-presentation"></i> -->
      </div>
    </div>
  </div>

  <app-presentation *ngIf="proofArray.length === 3" [proofArray]="proofArray"></app-presentation>

  <div class="col-xl-6 col-lg-6 col-md-8 col-sm-10 mx-auto p-3 pt-3" *ngIf="isIssuance">
    <div class="card border-0 card_shadow">
      <div class="card-body">
        <h3 style="text-align: center;">Ihr {{credentialType | getLang}} wird nun ausgestellt.
        </h3>
      </div>
    </div>
  </div>

  <div *ngIf="statusOk && messageText.length === 0 && !isIssuance"
    class="col-xl-6 col-lg-6 col-md-8 col-sm-10 mx-auto p-3 pt-3">
    <div class="card border-0 card_shadow">
      <div class="card-body">

        <p>Herzlichen Glückwunsch zu Ihrem Mobilfunkvertrag.</p>
        <p><strong>Kundennummer</strong> {{credSubject.customerId}}</p>
        <p><strong>Telefonnummer</strong> {{credSubject.phoneNumber}}</p>
      </div>
    </div>
  </div>

  <div style="display: block;" class="vh-73" *ngIf="!isIssuance">
    <div class="col-xl-6 col-lg-6 col-md-8 col-sm-10 mx-auto p-3 pt-3" *ngIf="showQrCode">
      <div class="card border-0 card_shadow">
        <app-showqrcode class="qr-code" *ngIf="showQrCode" [verificationUrl]="appUrl"
          [isRequestPresentation]="isRequestPresentation" [isDresdenPass]="false"></app-showqrcode>
      </div>
    </div>
    <div
      [ngClass]="{'flex-wrapper': true, 'none': (showQrCode && proofArray.length <5) || (!showQrCode && proofArray.length === 1)}">
      <button *ngIf="!showQrCode && !isIssuance && !statusOk && steps > 0" type="submit" (click)="onSubmit()"
        class="link-box-white">{{buttonText}}</button>
    </div>
  </div>
</div>

<dialog id="dialog-box">
  <h3>Möchten Sie die Eingabe Ihrer Daten beenden und zur Credentialauswahl zurückkehren?</h3>
  <div class="inline">
    <button (click)="onDialogCancel()" class="link-box-white">Nein</button>
    <button (click)="onDialogOk()" class="link-box-white">Ja</button>
  </div>
</dialog>
<dialog id="dialog-box-alert">
  <h3>Es ist ein Fehler aufgetreten</h3>
  <p>{{alertMessage}}</p>
  <p *ngIf="hintMessage.length > 0">{{hintMessage}}</p>
  <div class="inline">
    <button (click)="onDialogBack()" class="link-box-white">Ok</button>
  </div>
</dialog>
<dialog id="dialog-box-presentation-invalid">
  <h3>Die Präsentation des Bank-Verfügungscredentials war nicht erfolgreich.</h3>
  <p>Bitte versuchen Sie es erneut</p>
  <div class="inline">
    <button (click)="onDialogPresentationInvalid()" class="link-box-white">Ok</button>
  </div>
</dialog>
<ng-container *ngFor="let message of messageArray">
  <app-toaster *ngIf="message.length > 0" [messageText]="message" [status]="statusOk"></app-toaster>
</ng-container>