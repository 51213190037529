<div class="col-xl-6 col-lg-6 col-md-8 col-sm-10 mx-auto p-3 pt-3">
    <div class="card border-0 card_shadow">
        <div class="card-body">

            <div class="row mx-auto mb-3">
                <div class="col-lg-4 col-md-4 col-md-4 col-form-label">
                    <div class="col-elementLabel">
                        Folgende Nachweise wurden erfolgreich präsentiert:
                    </div>
                </div>
                <div class="col-lg-8 col-md-8 col-sm-8">
                    <div class="col-elementValue">
                        <ol>
                            <li *ngFor="let proof of proofArray | keyvalue">{{proof.value | getLang}}</li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>