<header class="heading">Eröffnung eines Bankkontos</header>
<div class="main-wrapper container-fluid" style="display: block;">
  <div *ngIf="!statusOk && requestPresentation && !showQrCode && (step === 0)"
    class="col-xl-6 col-lg-6 col-md-8 col-sm-10 mx-auto p-3 pt-3">
    <div
      [ngClass]="step === 0 ? 'card border-0 card_shadow card-fade': 'card border-0 card_shadow card-removed'">
      <div class="card-body">
        <p>Wir freuen uns, dass Sie ein Bankkonto bei uns eröffnen möchten.</p>
        <p>Bitte geben sie uns ihren Namen, ihr Geburtsdatum und ihre Adresse. Wir sind gesetzlich verpflichtet diese
          Informationen aufzunehmen.</p>
        <p>Sie können mit ihrer IDealWALLET geht das ganz einfach. Im nächsten Schritt zeigen wir ihnen wie sie
          vorgehen.</p>
      </div>
    </div>
  </div>
  <div *ngIf="!statusOk && requestPresentation && !showQrCode && (step === 1)"
    class="col-xl-6 col-lg-6 col-md-8 col-sm-10 mx-auto p-3 pt-3">
    <div
      [ngClass]="step === 1 || step === 2? 'card border-0 card_shadow card-fade': 'card border-0 card_shadow card-removed'">
      <div class="card-body image-container">
        <img [src]="imgURL + 'Bank_QR_Phone.png'" id="landscape">
        <i class="fa-solid fa-arrow-left" id="bank-qr"></i>
      </div>
    </div>
  </div>
  <div *ngIf="!statusOk && requestPresentation && !showQrCode && (step === 2)"
    class="col-xl-6 col-lg-6 col-md-8 col-sm-10 mx-auto p-3 pt-3">
    <div
      [ngClass]="step === 2 || step === 3? 'card border-0 card_shadow card-fade': 'card border-0 card_shadow card-removed'">
      <div class="card-body image-container">
        <video [src]="imgURL + 'IDeal WALLET_ScanQR.mp4'" controls autoplay></video>
      </div>
    </div>
  </div>
  <div *ngIf="!statusOk && requestPresentation && !showQrCode && (step === 3)"
    class="col-xl-6 col-lg-6 col-md-8 col-sm-10 mx-auto p-3 pt-3">
    <div
      [ngClass]="step === 3 || step === 4? 'card border-0 card_shadow card-fade': 'card border-0 card_shadow card-removed'">
      <div class="card-body">

        <p>Folgende Nachweise werden abgefragt:</p>
        <ol>
          <li>KommPass Person</li>
          <li>KommPass Geburtsurkunde</li>
          <li>KommPass Adresse.</li>
        </ol>
        <img>
        <p>Bitte bestätigen Sie die Präsentation dieser Nachweise</p>
        <div class="image-container">
          <img [src]="imgURL + 'A53+IDeal WALLET_ReviewPresentationRequest.png'" class="portrait">
        </div>
        <i class="fa-solid fa-arrow-left" id="request-presentation"></i>
      </div>
    </div>
  </div>
  <div *ngIf="!statusOk && requestPresentation && !showQrCode && (step === 4)"
    class="col-xl-6 col-lg-6 col-md-8 col-sm-10 mx-auto p-3 pt-3">
    <div
      [ngClass]="step === 4 ? 'card border-0 card_shadow card-fade': 'card border-0 card_shadow card-removed'">
      <div class="card-body">
        <p>In der Nachweisansicht wird Ihnen nun Ihr Bank-Verfügungscredential angezeigt.</p>
        <div class="image-container">
          <img [src]="imgURL + 'A53+IDeal WALLET_CredentialsBADA.png'" class="portrait">
          <i class="fa-solid fa-arrow-left" id="credential-bada"></i>
        </div>
      </div>
    </div>
  </div>
  <app-presentation *ngIf="proofArray.length === 3" [proofArray]="proofArray"></app-presentation>

  <div *ngIf="isIssuance">
    <div>
      <div class="col-xl-6 col-lg-6 col-md-8 col-sm-10 mx-auto p-3 pt-3">
        <div class="card border-0 card_shadow">
          <div class="card-body">
            <h4>Ihr Konto wird nun eröffnet.</h4>
            <p>Bitte halten Sie Ihre IDeal Wallet aktiv. Ihr Bankkonto-Verfügungsberechtigungs-Credential
              wird ausgestellt.</p>
            <div class="row mx-auto mb-3" *ngFor="let entry of displayObject | keyvalue">
              <div class="col-lg-4 col-md-4 col-md-4 col-form-label">
                <div class="col-elementLabel">
                  <div class="elementValue">
                    <label>{{entry.key | getLang}}</label>
                  </div>
                </div>
              </div>

              <div class="col-lg-8 col-md-8 col-sm-8">
                <div class="col-elementValue">
                  <div class="elementValue">
                    <p>{{entry.value | getLang}}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
  <div *ngIf="statusOk && messageText.length === 0 && !isIssuance"
    class="col-xl-6 col-lg-6 col-md-8 col-sm-10 mx-auto p-3 pt-3">
    <div class="card border-0 card_shadow">
      <div class="card-body">

        <p>Herzlichen Glückwunsch zu Ihrem Bankkonto.</p>
        <p><strong>Kreditinstitut</strong> {{credSubject.creditInstitution}}</p>
        <p><strong>IBAN</strong> {{credSubject.iban}}</p>
        <p><strong>BLZ</strong> {{credSubject.bic}}</p>
      </div>
    </div>
  </div>
  <div style="display: block;" class="vh-73" *ngIf="!isIssuance">
    <div class="col-xl-6 col-lg-6 col-md-8 col-sm-10 mx-auto p-3 pt-3" *ngIf="showQrCode">
      <div class="card border-0 card_shadow">
        <app-showqrcode class="qr-code" *ngIf="showQrCode" [verificationUrl]="appUrl"
          [isRequestPresentation]="isRequestPresentation" [isDresdenPass]="false" [heading]="heading"></app-showqrcode>
      </div>
    </div>
    <div
      [ngClass]="{'flex-wrapper': true, 'none': (showQrCode && proofArray.length <3) || (!showQrCode && proofArray.length === 1)}">
      <button *ngIf="!showQrCode && !isIssuance && !statusOk" type="submit" (click)="onSubmit()"
        class="link-box-white">{{buttonText}}</button>
    </div>
  </div>
</div>
<dialog id="dialog-box">
  <h3>Möchten Sie die Eingabe Ihrer Daten beenden und zur Credentialauswahl zurückkehren?</h3>
  <div class="inline">
    <button (click)="onDialogCancel()" class="link-box-white">Nein</button>
    <button (click)="onDialogOk()" class="link-box-white">Ja</button>
  </div>
</dialog>
<dialog id="dialog-box-alert">
  <h3>Es ist ein Fehler aufgetreten</h3>
  <p>{{alertMessage}}</p>
  <p *ngIf="hintMessage.length > 0">{{hintMessage}}</p>
  <div class="inline">
    <button (click)="onDialogBack()" class="link-box-white">Ok</button>
  </div>
</dialog>
<dialog id="dialog-box-presentation-invalid">
  <h3>Die Präsentation des Bank-Verfügungscredentials war nicht erfolgreich.</h3>
  <p>Bitte versuchen Sie es erneut</p>
  <div class="inline">
    <button (click)="onDialogPresentationInvalid()" class="link-box-white">Ok</button>
  </div>
</dialog>
<ng-container *ngFor="let message of messageArray">
  <app-toaster *ngIf="message.length > 0" [messageText]="message" [status]="statusOk"></app-toaster>
</ng-container>