<header>Standinhaber</header>

<div class="main-wrapper container-fluid flex-wrapper">
  <div (click)="onClick('Landeshauptstadt Dresden')" [routerLink]="['/besuchernachweis/']"
    class="link-box link-box-dresden align-middle flex-grow-1 flex-shrink-1 flex-basis-0" routerLinkActive="active">
    <div class="scc2023-logo">
      <img [src]="imgURL + 'dresden.png'" class="scc2023-logo-dresden" />
    </div>
    <span class="scc2023-title">Landeshauptstadt Dresden</span>
  </div>
  <div (click)="onClick('Stadt Leipzig')" [routerLink]="['/besuchernachweis/']"
    class="link-box link-box-leipzig align-middle flex-grow-1 flex-shrink-1 flex-basis-0" routerLinkActive="active">
    <div class="scc2023-logo">
      <img [src]="imgURL + 'Leipzig_weiss.webp'" class="scc2023-logo-leipzig" />
    </div>
    <span class="scc2023-title">Stadt Leipzig</span>
  </div>
  <div (click)="onClick('Trustnet Café')" [routerLink]="['/besuchernachweis/']"
    class="link-box link-box-trustnet align-middle flex-grow-1 flex-shrink-1 flex-basis-0" routerLinkActive="active">
    <div class="scc2023-logo">
      <img [src]="imgURL + 'trustnet_weiss.webp'" class="scc2023-logo-trustnet" />
    </div>
    <span class="scc2023-title">Trustnet Café</span>
  </div>
  <div (click)="onClick('Hochschule Mittweida')" [routerLink]="['/besuchernachweis/']"
    class="link-box link-box-hochschulemittweida align-middle flex-grow-1 flex-shrink-1 flex-basis-0" routerLinkActive="active">
    <div class="scc2023-logo">
      <img [src]="imgURL + 'hochschulemittweida.png'" class="scc2023-logo-hochschulemittweida" />
    </div>
    <span class="scc2023-title">Hochschule Mittweida</span>
  </div>
  <div (click)="onClick('EECC European EPC Competence Center')" [routerLink]="['/besuchernachweis/']"
    class="link-box link-box-EECC flex-grow-1 flex-shrink-1 flex-basis-0 white-space-nowrap" routerLinkActive="active">
    <div class="scc2023-logo">
      <img [src]="imgURL + 'EECC.webp'" class="scc2023-logo-EECC" />
    </div>
    <span class="scc2023-title">European EPC Competence Center (EECC)</span>
  </div>
  <div (click)="onClick('Fraunhofer FIT')" [routerLink]="['/besuchernachweis/']"
    class="link-box link-box-fraunhoferFIT align-middle flex-grow-1 flex-shrink-1 flex-basis-0" routerLinkActive="active">
    <div class="scc2023-logo">
      <img [src]="imgURL + 'fraunhoferfit.png'" class="scc2023-logo-fraunhoferFIT" />
    </div>
    <span class="scc2023-title">Fraunhofer FIT</span>
  </div>
  <div (click)="onClick('KAPRION Technologies GmbH')" [routerLink]="['/besuchernachweis/']"
    class="link-box link-box-kaprion align-middle flex-grow-1 flex-shrink-1 flex-basis-0" routerLinkActive="active">
    <div class="scc2023-logo">
      <img [src]="imgURL + 'kaprion.png'" class="scc2023-logo-kaprion" />
    </div>
    <span class="scc2023-title-kaprion">KAPRION Technologies GmbH</span>
  </div>
</div>

<!-- <div class="main-wrapper flex-wrapper">
  <div class="seperator"></div>
  <button (click)="onClickBack()" class="link-box-white">Zurück</button>
</div> -->