export interface Ticket {
  id: number,
  zone: string[],
  priceLevel: string
  priceLevelNo: number,
  price: {
    name:string,
    value:number
  },
  requiresCredentials: boolean,
  message: string
}

const msgTageskarte: string = "Gilt für 1 Person und maximal 2 Schüler bis zum 15. Geburtstag in der angegebenen Tarifzone bis 4:00 Uhr Folgetag für beliebig viele Fahrten.";
const msgMonatskarte: string = "Gilt für 1 Person. Monatskarten zum Normalfahrpreis sind übertragbar. Zwischen 18 Uhr und 4 Uhr einen weiteren Erwachsenen und bis zu vier Schüler bis zum 15. Geburtstag mitnehmen. Dies gilt nicht in den Sonderverkehrsmitteln.";
const msgEinzelfahrt: string = "Gilt in der angegebenen Tarifzone für 1 Person; max. 1 Std.";
const msgDeutschlandticket:string = "Gilt ab Entwertung für den jeweiligen Kalendermonat. Nicht übertragbar, Personengebunden.";
export enum TicketType {
  TAGESKARTE = 'Tageskarte',
  EINZELFAHRT = 'Einzelfahrt',
  DEUTSCHLANDTICKET = 'Deutschlandticket'
}

export const ticketPreSet: Ticket[] =  [
  { id: 0,  zone:['Dresden'],           priceLevel: 'A1', priceLevelNo: 1, price: { name: TicketType.TAGESKARTE,  value: 8.00 }, requiresCredentials: false, message: msgTageskarte },
  { id: 1,  zone:['Dresden'],           priceLevel: 'A1', priceLevelNo: 1, price: { name: TicketType.EINZELFAHRT, value: 3.00 }, requiresCredentials: false, message: msgEinzelfahrt },
  { id: 2,  zone:['Freital'],           priceLevel: 'A', priceLevelNo: 2, price: { name: TicketType.TAGESKARTE,  value: 6.90 }, requiresCredentials: false, message: msgTageskarte },
  { id: 3,  zone:['Freital'],           priceLevel: 'A', priceLevelNo: 2, price: { name: TicketType.EINZELFAHRT, value: 2.80 }, requiresCredentials: false, message: msgEinzelfahrt },
  { id: 4,  zone:['Pirna'],             priceLevel: 'A', priceLevelNo: 2, price: { name: TicketType.TAGESKARTE,  value: 6.90 }, requiresCredentials: false, message: msgTageskarte },
  { id: 5,  zone:['Pirna'],             priceLevel: 'A', priceLevelNo: 2, price: { name: TicketType.EINZELFAHRT, value: 2.80 }, requiresCredentials: false, message: msgEinzelfahrt },
  { id: 6,  zone:['Dresden','Pirna'],   priceLevel: 'B', priceLevelNo: 3, price: { name: TicketType.TAGESKARTE, value: 11.00 }, requiresCredentials: false, message: msgTageskarte },
  { id: 7,  zone:['Dresden','Freital'], priceLevel: 'B', priceLevelNo: 3, price: { name: TicketType.TAGESKARTE, value: 11.00 }, requiresCredentials: false, message: msgTageskarte },
  { id: 8,  zone:['Pirna','Freital'],   priceLevel: 'B', priceLevelNo: 3, price: { name: TicketType.TAGESKARTE, value: 11.00 }, requiresCredentials: false, message: msgTageskarte },
  { id: 9,  zone:['Dresden','Pirna'],   priceLevel: 'B', priceLevelNo: 3, price: { name: TicketType.EINZELFAHRT, value: 5.30 }, requiresCredentials: false, message: msgEinzelfahrt },
  { id: 10, zone:['Dresden','Freital'], priceLevel: 'B', priceLevelNo: 3, price: { name: TicketType.EINZELFAHRT, value: 5.30 }, requiresCredentials: false, message: msgEinzelfahrt },
  { id: 11, zone:['Pirna','Freital'],   priceLevel: 'B', priceLevelNo: 3, price: { name: TicketType.EINZELFAHRT, value: 5.30 }, requiresCredentials: false, message: msgEinzelfahrt },
  { id: 12, zone:['Verbund'],           priceLevel: 'D', priceLevelNo: 5, price: { name: TicketType.TAGESKARTE,  value: 18.00 }, requiresCredentials: false, message: msgTageskarte },
  { id: 13, zone:['Verbund'],           priceLevel: 'D', priceLevelNo: 5, price: { name: TicketType.EINZELFAHRT, value: 10.60 }, requiresCredentials: false, message: msgEinzelfahrt },
  { id: 14, zone:['Deutschland'],       priceLevel: '',  priceLevelNo: 0, price: { name: TicketType.DEUTSCHLANDTICKET, value: 49.00 }, requiresCredentials: true, message: msgDeutschlandticket },
  { id: 15, zone:['Dresden', 'Freital', 'Pirna'], priceLevel: 'C', priceLevelNo: 4, price: {name: TicketType.EINZELFAHRT, value: 5.40 }, requiresCredentials: false, message: msgEinzelfahrt }
];

