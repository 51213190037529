import { Issuer } from "./issuer.model";
import { PresentationDefinition } from "./presentation-definition.model";

export class CredentialManifest {
    id: string;
    spec_version: string;
    issuer: Issuer;
    output_descriptors: {[key: string]: any}[];
    presentation_definition?: PresentationDefinition | null; //only when inputdescriptors --> inputdescriptors for required cred
    credentialApplication?: null;

    constructor(ver: string, iss: Issuer, outputDesc: {[key: string]: any}[], uuid: string, inputDesc: {[key: string]: any}[]) {
        this.id = "manifest_id_" + uuid;
        this.spec_version = ver;
        this.issuer = iss;
        this.output_descriptors = outputDesc;
        if (inputDesc.length > 0) {
            this.presentation_definition = new PresentationDefinition(crypto.randomUUID(), inputDesc);
        }
    }
}
