/**
 * ValidationRequestPresentationModel describes the `request-presentation` message for the DIDComm WACI present proof protocol.
 *
 * @author tehrlich
 * @version 2023-10-17
 */
export class ValidationRequestPresentationModel {
  type: string = "de.kaprion.ppp.s2p.requestPresentation.res";
  oobid: string = "";
  presentation_definition: PresentationDefinition = {
    id: "",
    input_descriptors: []
  }

  /**
   * Default Constructor
   *
   * @param oobid a string with a unique Out-of-Band ID used to identify the Wallet to Wallet session
   * @param presentationID a string with a nonce for this presentation request
   * @param inputDescriptors an array with input descriptors represented in type strings or full defined objects
   */
  constructor(oobid: string, presentationID: string, inputDescriptors: { [key: string]: any }[] | string[]) {
    this.oobid = oobid;
    this.presentation_definition.id = presentationID
    this.presentation_definition.input_descriptors = inputDescriptors
  }

  /**
   * Returns a JSON with the structure
   *
   * <pre>
   *   {
   *       type: "de.kaprion.ppp.s2p.requestPresentation.res",
   *       oobid: "OOBID",
   *       presentation_definition: {
   *          id: "nonce",
   *          input_descriptors: []
   *        }
   *   }
   * </pre>
   */
  toJSON() {
    return {
      type: this.type,
      oobid: this.oobid,
      presentation_definition: this.presentation_definition
    }
  }
}

/**
 * The sub-interface for the presentation_definition object
 */
interface PresentationDefinition {
  id: string,
  input_descriptors: { [key: string]: any } | string[]
}
