<!-- Toolbar -->
<div [ngClass]="{'toolbar': true, 'toolbar-dresden': currentRouteHeader === 'DRESDEN', 'toolbar-dvb': currentRouteHeader === 'DVB', 
  'toolbar-idi': currentRouteHeader === 'IDI', 'toolbar-jh': currentRouteHeader === 'JH', 'toolbar-teil': currentRouteHeader === 'teil',
  'toolbar-next': currentRouteHeader === 'next', 'toolbar-mobile': currentRouteHeader === 'CALLE' }" role="banner">
  <img [class]="currentHeaderMenuIcon.icon_class" alt="Menu" [src]="imgURL + currentHeaderMenuIcon.img"
    (click)="showMenu()" title="Hier klicken, um das Menü anzuzeigen" />
  <img *ngIf="currentRouteHeader === 'KAPRION'" class="logo-icon" alt="KAPRION" [src]="imgURL + 'kaprion_logo.png'"
    title="Version vom: {{timestamp}}" />
  <img *ngIf="currentRouteHeader === 'DRESDEN'" class="logo-icon logo-icon-dresden" alt="DRESDEN"
    [src]="imgURL + 'dresden_logo.png'" title="Version vom: {{timestamp}}" />
  <img *ngIf="currentRouteHeader === 'DVB'" class="logo-icon" alt="DVB" [src]="imgURL + 'dvb_logo.png'"
    title="Version vom: {{timestamp}}" />
  <img *ngIf="currentRouteHeader === 'IDI'" class="logo-icon logo-icon-idi" alt="IDI"
    [src]="'data:image/svg+xml;base64,'+ idiLogo" title="Version vom: {{timestamp}}" />
  <img *ngIf="currentRouteHeader === 'JH'" class="logo-icon logo-icon-JH" alt="Jungheinrich"
    [src]="'data:image/webp;base64,'+ jhLogo" title="Version vom: {{timestamp}}" />
  <div *ngIf="isShowMenu" [class]="currentHeaderMenuIcon.menu_class" (mouseleave)="hideMenu()">
    <div *ngFor="let item of useCases">
      <div *ngIf="currentHeaderMenuIcon.menu_class === 'menu-popup-kaprion'"
        [ngClass]="{'menu-popup-buttons-kaprion': true, 'selected-menu-popup-button-kaprion': selectedMenuIndex === item.id}"
        title="{{item.title}}" routerLink="{{item.path}}" (click)="menuSelect(item.id)">{{item.title}}</div>
      <div *ngIf="currentHeaderMenuIcon.menu_class === 'menu-popup-dresden'"
        [ngClass]="{'menu-popup-buttons-dresden': true, 'selected-menu-popup-button-dresden': selectedMenuIndex === item.id}"
        title="{{item.title}}" routerLink="{{item.path}}" (click)="menuSelect(item.id)">{{item.title}}</div>
      <div *ngIf="currentHeaderMenuIcon.menu_class === 'menu-popup-dvb'"
        [ngClass]="{'menu-popup-buttons-dvb': true, 'selected-menu-popup-button-dvb': selectedMenuIndex === item.id}"
        title="{{item.title}}" routerLink="{{item.path}}" (click)="menuSelect(item.id)">{{item.title}}</div>
      <div *ngIf="currentHeaderMenuIcon.menu_class === 'menu-popup-idi'"
        [ngClass]="{'menu-popup-buttons-idi': true, 'selected-menu-popup-button-idi': selectedMenuIndex === item.id}"
        title="{{item.title}}" routerLink="{{item.path}}" (click)="menuSelect(item.id)">{{item.title}}</div>
      <div *ngIf="currentHeaderMenuIcon.menu_class === 'menu-popup-calle'"
        [ngClass]="{'menu-popup-buttons-calle': true, 'selected-menu-popup-button-calle': selectedMenuIndex === item.id}"
        title="{{item.title}}" routerLink="{{item.path}}" (click)="menuSelect(item.id)">{{item.title}}</div>
      <div *ngIf="currentHeaderMenuIcon.menu_class === 'menu-popup-black'"
        [ngClass]="{'menu-popup-buttons-black': true, 'selected-menu-popup-button-black': selectedMenuIndex === item.id}"
        title="{{item.title}}" routerLink="{{item.path}}" (click)="menuSelect(item.id)">{{item.title}}</div>
      <div *ngIf="currentHeaderMenuIcon.menu_class === 'menu-popup-teil'"
        [ngClass]="{'menu-popup-buttons-teil': true, 'selected-menu-popup-button-teil': selectedMenuIndex === item.id}"
        title="{{item.title}}" routerLink="{{item.path}}" (click)="menuSelect(item.id)">{{item.title}}</div>
      <div *ngIf="currentHeaderMenuIcon.menu_class === 'menu-popup-next'"
        [ngClass]="{'menu-popup-buttons-next': true, 'selected-menu-popup-button-next': selectedMenuIndex === item.id}"
        title="{{item.title}}" routerLink="{{item.path}}" (click)="menuSelect(item.id)">{{item.title}}</div>
    </div>
    <div>
      <aside class="system">{{environment}}<br />&#64; Version: {{timestamp}}</aside>
    </div>
  </div>
  <div class="right">
    <a
      href="https://www.digitale-technologien.de/DT/Navigation/DE/ProgrammeProjekte/AktuelleTechnologieprogramme/Sichere_Digitale_Identitaeten/Projekte_Umsetzungsphase/IDideal/IDideal.html"><img
        class="logo-icon scale right" alt="BMWK Logo" [src]="imgURL + 'BMWK_Fz_2017_WebSVG_de.svg'"
        title="Gefördert durch das BMWK" /></a>
    <a href="https://id-ideal.de/"><img
        [ngClass]="{'logo-icon': true, 'logo-icon-white-background': (currentRouteHeader === 'DRESDEN' || currentRouteHeader === 'IDI'), 'scale': true, 'right': true}"
        alt="ID-Ideal-Logo" [src]="imgURL + 'Logo_ID-Ideal_transparent.svg'" title="ID-Ideal" /></a>
  </div>
</div>
<div class="content" role="main">

  <router-outlet></router-outlet>

</div>