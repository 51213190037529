import { Injectable } from '@angular/core';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
import { EMPTY, Subject, Observable, Observer } from 'rxjs';
import { LocalDataStorageService } from './local-data-storage.service';
import { map } from "rxjs/operators";
import { EnvService, environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class WebsocketService {
  //important follow structure on server
  messages: Subject<any>;
  webSocket!: WebSocket;
  socketURL;

  constructor(private localDataStorageService: LocalDataStorageService, private envService: EnvService) {
    this.messages = new Subject();
    this.socketURL = "wss://" + this.envService.env['CS_URL'] + ":443/connectSession/"
  }
  private subject: Subject<MessageEvent> = new Subject();

  public connect(): void {
    this.socketURL += this.localDataStorageService.getData("sessionId");
    if (!this.subject) {
      this.subject = this.create(this.socketURL);
      console.log("Successfully connected: " + this.socketURL);
    }

    this.messages = <Subject<any>>this.subject.pipe(map(
      (response: MessageEvent) => {
        return response.data;
      }
    )
    );
  }

  public checkSocketStatus() {
    return this.webSocket.readyState === WebSocket.OPEN ? true : false;
  }

  private create(url: string): Subject<MessageEvent> {
    this.webSocket = new WebSocket(url);

    let observable = Observable.create((obs: Observer<MessageEvent>) => {
      this.webSocket.onmessage = obs.next.bind(obs);
      this.webSocket.onerror = obs.error.bind(obs);
      this.webSocket.onclose = obs.complete.bind(obs);
      return this.webSocket.close.bind(this.webSocket);
    });
    let observer = {
      next: (data: Object) => {
        if (this.webSocket.readyState === WebSocket.OPEN) {
          this.webSocket.send(JSON.stringify(data));
        }
      }
    };
    return Subject.create(observer, observable);
  }

  sendMessage(message: string): void {
    this.webSocket.send(message);
  }

  messagesSubscription(message = null) {
    const isOpen = this.checkSocketStatus();
    if (!isOpen) {
        this.connect();
    } else {
        this.messages = this.messages;
    }
    return this.messages;
}

  closeConnection(): void {
    this.webSocket.close();
  }
}
