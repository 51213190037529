import {Injectable} from '@angular/core';
import {VerifiableCredential1xModel} from "../models/w3c/vcdm/verifiable-credential-1x.model";
import {EvidenceModel} from "../models/w3c/vcdm/evidence.model";
import {CredentialSchemaModel} from "../models/w3c/vcdm/credential-schema.model";
import {RefreshServiceModel} from "../models/w3c/vcdm/refresh-service.model";

/**
 * Credential Builder Controller generates W3C Verifiable Credentials from parts
 *
 * TODO add missing functions
 *
 * @author tehrlich
 * @version 2023-09-11
 */
@Injectable({providedIn: 'root'})
export class CredentialBuilderService {

  private credential : VerifiableCredential1xModel;

  /**
   * Default constructor generates en empty credential
   */
  constructor() {
    this.credential = {
      "@context": ["https://www.w3.org/2018/credentials/v1"],
      type: [
        "VerifiableCredential"
      ],
      issuer: "iss:REPLACE_ME_ISSUER",
      issuanceDate: "",
      credentialSubject: {}
    }
  }

  /**
   * Generates a ISO 8601 date-time string.
   *
   * @param isDateTime true if date-time shall be returned, false if just the date is expected
   * @param deltaYears the years relative to the current year, if for example a date in the future is expected. The time will be reset to midnight.
   */
  public getDateTime(isDateTime: boolean = true, deltaYears: number = 0) : string {
    let date = new Date();
    if (isDateTime) {
      if (deltaYears > 0) {
        date.setHours(0,0,0,0); // reset hours, minutes, seconds and milliseconds
        date.setFullYear(date.getFullYear() + deltaYears); // update year
      }
      return date.toISOString();
    } else {
      if (deltaYears > 0) {
        date.setFullYear(date.getFullYear() + deltaYears);
      }
      return date.toISOString().split("T")[0];
    }
  }

  /**
   * Adds a JSON-LD context URI string
   *
   * @param context an URI with the target context file
   */
  public addContext(context: string) : this {
    this.credential["@context"].push(context);
    return this;
  }

  /**
   * Sets a W3C VC Identifier for the credential
   *
   * @see <a href="https://www.w3.org/TR/2022/REC-vc-data-model-20220303/#identifiers">W3C Verifiable credential Data Model 1.x - Identifier</a>
   * @param id an unique URI that makes it possible to identify this specific credential
   */
  public id(id: string) : this {
    this.credential.id = id;
    return this;
  }

  /**
   * Adds a credential type to the type array
   *
   * @param type
   */
  public addType(type: string) : this {
    this.credential.type.push(type);
    return this;
  }

  public getType(): string {
    let rType: string = ""
    this.credential.type.forEach(type => {
      if (type !== "VerifiableCredential") { rType = type}
    })
    return rType;
  }

  /**
   * Sets the issuer ID. The default value is <code>iss:REPLACE_ME_ISSUER</code> and will be replaced by the signing service.
   *
   * @param issuer the issuer identifier @see <a href="https://www.w3.org/TR/2022/REC-vc-data-model-20220303/#issuer"> W3C Verifiable credential Data Model 1.x - Issuer</a>
   */
  public issuer(issuer: string) : this {
    this.credential.issuer = issuer;
    return this;
  }

  /**
   * Sets the issuance date-time, the default value is an ISO 8601 date-time string produced by now()
   *
   * @param issuanceDate the issuance date-time string (optional)
   */
  public issuanceDate(issuanceDate: string = this.getDateTime()) : this {
    this.credential.issuanceDate = issuanceDate;
    return this;
  }

  /**
   * Sets the expiration date-time, the default value is an ISO 8601 date-time string produced by now() + 10 years
   *
   * @param expirationDate the issuance date-time string (optional)
   */
  public expirationDate(expirationDate: string = this.getDateTime(true, 10)) : this {
    this.credential.expirationDate = expirationDate;
    return this;
  }

  /**
   * Adds a credential subject object to the credential
   *
   * @param credentialSubject the object that describes a subject
   */
  public credentialSubject(credentialSubject: object) : this {
    this.credential.credentialSubject = credentialSubject;
    return this;
  }

  /**
   * Adds an evidence object to the credential
   *
   * @param evidence the evidence object
   */
  public evidence(evidence: EvidenceModel[]) : this {
    this.credential.evidence = evidence;
    return this;
  }

  /**
   * Adds an evidence object to the credential
   *
   * @param evidence the evidence object
   */
  public addEvidence(evidence: EvidenceModel[]) : this {
    if (Array.isArray(this.credential.evidence)) { this.credential.evidence = evidence; }
    else { this.credential.evidence = evidence; }
    return this;
  }

  /**
   * Todo add desc
   * @param termsOfUse
   */
  public addTermsOfUse(termsOfUse: { [key: string]: any }) : this {
    if (this.credential.termsOfUse === undefined || this.credential.termsOfUse === null) { this.credential.termsOfUse = [] }
    this.credential.termsOfUse.push(termsOfUse);
    return this;
  }

  /**
   * TODO add desc
   * @param credentialSchema
   */
  public credentialSchema(credentialSchema: CredentialSchemaModel) : this {
    this.credential.credentialSchema = credentialSchema;
    return this;
  }

  /**
   * W3C VC Refreshing Service
   * @see https://www.w3.org/TR/2022/REC-vc-data-model-20220303/#refreshing
   * @param refreshService
   */
  public refreshService(refreshService: RefreshServiceModel) : this {
    this.credential.refreshService = refreshService;
    return this;
  }

  /**
   * todo add desc
   * @param proof
   */
  public proof(proof: { [key: string]: any }) : this {
    this.credential.proof = proof;
    return this;
  }

  /**
   * todo add desc
   * @param proof
   */
  public addProof(proof: { [key: string]: any }) : this {
    if (this.credential.proof === undefined || this.credential.proof === null) { this.credential.proof = []; }
    this.credential.proof.push(proof);
    return this;
  }

  /**
   * todo add desc
   * @param proof
   */
  public addProofArray(proof: { [key: string]: any }[]) : this {
    this.credential.proof = proof;
    return this;
  }

  /**
   * Resets the pre-stored values to an empty credential
   */
  public reset() : this {
    this.credential = {
      "@context": ["https://www.w3.org/2018/credentials/v1"],
      type: [
        "VerifiableCredential"
      ],
      issuer: "iss:REPLACE_ME_ISSUER",
      issuanceDate: "",
      credentialSubject: {}
    }
    return this;
  }

  /**
   * Returns the W3C Verifiable Credential according to the W3C Verifiable Credential Data Model 1.x
   * <p>
   * @see <a href="https://www.w3.org/TR/2022/REC-vc-data-model-20220303/"> W3C Verifiable credential Data Model 1.x</a>
   *
   * @return the W3C Verifiable Credential
   * @throws Error if the credential contains invalid values
   */
  public build() : VerifiableCredential1xModel {
    // TODO("add schema validator")
    const vc = this.credential;
    this.reset();
    return vc;
  }

  /**
   * Returns the W3C Verifiable Credential according to the W3C Verifiable Credential Data Model 1.x
   * <p>
   * @see <a href="https://www.w3.org/TR/2022/REC-vc-data-model-20220303/"> W3C Verifiable credential Data Model 1.x</a>
   *
   * @return the W3C Verifiable Credential
   * @throws Error if the credential contains invalid values
   */
  public buildJSONString() : string {
    // TODO("add schema validator")
    const vc = this.credential;
    this.reset();
    return JSON.stringify(vc);
  }
}
