import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PurposeGeneratorService {

  purposeSubject: {[key: string]: string} = {
    [CredentialType.KDKPersonK]: "Ihre Personendaten werden benötigt für ",
    [CredentialType.KDKAddressK]: "Ihre Adressdaten werden benötigt für ",
    [CredentialType.KDKAgeProofK]: "Ihre Altersverifikation wird benötigt für ",
    [CredentialType.KDKBirthCertificateK]: "Ihre Geburtsinformationen werden benötigt für",
    [CredentialType.KDKBiometricPhotoK]: "Ihr Lichtbild wird benötigt für ",
    [CredentialType.KommpassAdresse]: "Ihre Adressdaten werden benötigt für ",
    [CredentialType.KommpassAltersnachweis]: "Ihre Altersverifikation wird benötigt für ",
    [CredentialType.KommpassLichtbild]: "Ihr Lichtbild wird benötigt für ",
    [CredentialType.KommpassGeburtsurkunde]: "Ihre Geburtsinformationen werden benötigt für",
    [CredentialType.KommpassPerson]: "Ihre Personendaten werden benötigt für ",
    [CredentialType.BADA]: "Ihre Bankinformationen werden benötigt für ",
    [CredentialType.SEPADirectDebitMandate]: "Ihr SEPA-Lastschriftmandat wird benötigt für ",
    [CredentialType.Wohngeldbescheid]: "Ihr Wohngeldbescheid wird benötigt für",
    [CredentialType.Socialpass]: "Ihr Sozialpass wird benötigt für ",
    [CredentialType.SCC]: "Ihr Teilnahmenachweis wird benötigt für ",
    [CredentialType.VDVTicketDticket]: "Ihr Deutschlandticket wird benötigt für ",
    [CredentialType.VDVTicketTicket]: "Ihr Ticket wird benötigt für ",
    [CredentialType.MobileContract]: "Ihr Mobilfunknachweis wird benötigt für ",
    [CredentialType.Nextbike]: "Ihre Nextbike-Nutzungs-Authorisierung wird benötigt für ",
    [CredentialType.DrivingLicense]: "Ihre Fahrerlaubnis wird benötigt für ",
    [CredentialType.teilAuto]: "Ihre teilAuto-Nutzungs-Authorisierung wird benötigt für ",
    [CredentialType.Jungheinrich]: "Ihre Jungheinrich-Nutzungs-Authorisierung wird benötigt für ",
  }

  purposeObject: {[key: string]: string} = {
    [CredentialType.KDKPersonK]: "die Ausgabe eines KDK-Personen-Nachweises.",
    [CredentialType.KDKAddressK]: "die Ausgabe eines KDK-Adress-Nachweises.",
    [CredentialType.KDKAgeProofK]: "die Ausgabe eines KDK-Alters-Nachweises.",
    [CredentialType.KDKBirthCertificateK]: "die Ausgabe eines KDK-Geburtsurkunden-Nachweises.",
    [CredentialType.KDKBiometricPhotoK]: "die Ausgabe eines KDK-Lichtbild-Nachweises.",
    [CredentialType.KommpassAdresse]: "die Ausgabe eines KommPass-Adress-Nachweises.",
    [CredentialType.KommpassAltersnachweis]: "die Ausgabe eines KommPass-Alters-Nachweises.",
    [CredentialType.KommpassLichtbild]: "die Ausgabe eines KommPass-Lichtbild-Nachweises.",
    [CredentialType.KommpassGeburtsurkunde]: "die Ausgabe eines KommPass-Geburtsurkunden-Nachweises.",
    [CredentialType.KommpassPerson]: "die Ausgabe eines KommPass-Personen-Nachweises.",
    [CredentialType.BADA]: "die Ausgabe eines Bankverfügungs-Nachweises.",
    [CredentialType.SEPADirectDebitMandate]: "die Ausgabe eines SEPA-Lastschriftmandat-Nachweises.",
    [CredentialType.Wohngeldbescheid]: "die Ausgabe eines Wohngeld-Nachweises.",
    [CredentialType.Socialpass]: "die Ausgabe eines Sozialpassinhaberschaft-Nachweises.",
    [CredentialType.SCC]: "die Ausgabe eines Teilnahme-Nachweises.",
    [CredentialType.VDVTicketDticket]: "die Ausgabe eines Deutschlandticketinhaberschaft-Nachweises.",
    [CredentialType.VDVTicketTicket]: "die Ausgabe eines Ticketinhaberschaft-Nachweises.",
    [CredentialType.MobileContract]: "die Ausgabe eines Mobilfunkvertragsinhaberschaft-Nachweises.",
    [CredentialType.Nextbike]: "die Ausgabe eines Nextbike-Nutzungsauthorisierungs-Nachweises.",
    [CredentialType.DrivingLicense]: "die Ausgabe eines Fahrberechtigungs-Nachweises.",
    [CredentialType.teilAuto]: "die Ausgabe eines teilAuto-Nutzungsauthorisierungs-Nachweises.",
    [CredentialType.Jungheinrich]: "die Ausgabe eines Jungheinrich-Nutzungsauthorisierungs-Nachweises.",
  }

  constructor() { }

  /**
   * 
   * @param subject Credentialtype which needs to be presented
   * @param object Credentialtype which should be issued
   * @returns string that displays as purpose
   */
  private constructPurposeString(subject: string, object: CredentialType | null, additionalPurposeContent: string | null) {
    let purposeString: string = "";
    if (object !== null) {
      if (additionalPurposeContent !== null) {
        purposeString = this.purposeSubject[subject] + additionalPurposeContent[additionalPurposeContent.length-1] === ' ' ? this.purposeObject[object] : + " " + this.purposeObject[object];
      } else {
        purposeString = this.purposeSubject[subject] + this.purposeObject[object];
      }
    } else {
      purposeString = this.purposeSubject[subject].replace('für', 'zur') + "Kontrolle Ihrer Nutzungsauthorisierung."
    }
    return purposeString;
  }

  /**
   * 
   * @param subject Credentialtype which needs to be presented
   * @param object Credentialtype which should be issued
   * @returns string that displays as purpose
   */
  getPurposeString(subject: string, object: CredentialType | null, additionalPurposeContent: string | null = null) {
    return this.constructPurposeString(subject, object, additionalPurposeContent)
  }
}

export enum CredentialType {
  KDKPersonK,
  KDKAddressK,
  KDKAgeProofK,
  KDKBirthCertificateK,
  KDKBiometricPhotoK,
  KommpassAdresse,
  KommpassAltersnachweis,
  KommpassLichtbild,
  KommpassGeburtsurkunde,
  KommpassPerson,
  BADA,
  SEPADirectDebitMandate,
  Wohngeldbescheid,
  Socialpass,
  SCC,
  VDVTicketDticket,
  VDVTicketTicket,
  MobileContract,
  Nextbike,
  DrivingLicense,
  teilAuto,
  Jungheinrich
}
