// component allows to choose and buy tickets
import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment, EnvService } from '../../../environments/environment';
import { Ticket, ticketPreSet, TicketType } from '../../models/vvo/VVO';
import { AreaOfValidity, VDVTicketModel } from '../../models/kaprion/vc/vdvticket.model';
import { DataService } from '../../services/data.service';
import { CreateMessageService } from '../../services/create-message.service';
import { LocalDataStorageService } from '../../services/local-data-storage.service';
import { CredentialBuilderService } from '../../services/credential-builder.service';
import { MessageContainer } from '../../models/messages/messageContainer.model';
import { EvidenceModel } from '../../models/w3c/vcdm/evidence.model';
import { FormControl, FormGroup } from '@angular/forms';
import { Issuer } from '../../models/issuer.model';
import { VerifiableCredential1xModel } from '../../models/w3c/vcdm/verifiable-credential-1x.model';
import { GetLangPipe } from 'src/app/pipe/get-lang.pipe';
import { CredentialType, PurposeGeneratorService } from 'src/app/services/purpose-generator.service';


@Component({
  selector: 'app-ticketselection',
  templateUrl: './ticketbuy.component.html',
  styleUrls: ['./ticketbuy.component.scss']
})

export class TicketBuyComponent implements OnInit {

  @Input() sessionId = "";

  formatter = new Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: 'EUR'
  });

  //tarif-zone-variables
  tarifZoneAmount = 1;
  firstZone = false;
  secondZone = false;
  statusOk: boolean = false;

  showBiometricPhoto: boolean = false;

  messageText: string = "";
  messageArray: string[] = [];

  //oobid for qr-code
  oobid = 0;

  //flag for updateSelectedTicket
  isUpdatedTicket = false;

  tType: TicketType = TicketType.EINZELFAHRT;

  imgURL = environment.imgURL;

  isWaitingForResponse: boolean = false;

  proofArray: string[] = [];

  // main variable for selected ticket before credential
  selected: Ticket = {
    id: ticketPreSet[0].id,
    message: ticketPreSet[0].message,
    price: {
      name: ticketPreSet[0].price.name,
      value: ticketPreSet[0].price.value,
    },
    priceLevel: ticketPreSet[0].priceLevel,
    priceLevelNo: ticketPreSet[0].priceLevelNo,
    requiresCredentials: ticketPreSet[0].requiresCredentials,
    zone: ticketPreSet[0].zone
  }

  // variables for View of selected ticket in "cart"
  htmlMessage: string = ticketPreSet[0].message;
  htmlPrice: string = this.formatter.format(ticketPreSet[0].price.value);
  htmlInfo: string = this.updateInfo(ticketPreSet[0]);
  htmlValidFrom = '';
  htmlValidUntil = '';

  // to control different options for ticket
  hasPriceLevel = true;
  hasZone = true;
  hasSecondZone = false;
  selectedPriceLevel?: { name: string, selected: boolean } = undefined;
  selectedZones: [{ name: string, selected: boolean }, { name: string, selected: boolean }] = [{ name: "", selected: true }, { name: "", selected: false }]
  credentialArray: any[] = [];

  requestPresentation: any[] = ["SEPADirectDebitMandate"];
  isRequestPresentation: boolean = true;
  isIssuance: boolean = false;

  appUrl: string = "";

  alertMessage: string = "";
  hintMessage: string = "";
  displayObject: { [key: string]: any } = {}

  // site related variables including flags to control flow of present proof and issuance
  content: string = "";
  buttonText: string = "Ticket kaufen";
  isBuyNow: boolean = true;
  purposeObjectArray: {[key: string]: any}[] = [];

  credential: any = {};

  // list of ticket types
  ticketTypes = [
    { name: TicketType.DEUTSCHLANDTICKET, selected: false },
    { name: TicketType.TAGESKARTE, selected: false },
    { name: TicketType.EINZELFAHRT, selected: false }
  ]

  // list of price level
  priceLevel = [
    { name: 'A1', selected: true }, //Dresden
    { name: 'A', selected: false }, //1 Zone
    { name: 'B', selected: false }, //2 Zonen
    { name: 'C', selected: false }, // 1 TZ und umliegende
    { name: 'D', selected: false } //Verbund
  ];

  zoneAmount = [1, 2];

  zones = [
    { name: "Dresden", selected: true },
    { name: "Freital", selected: false },
    { name: "Pirna", selected: false },
    { name: "Verbund", selected: false }
  ];

  discount: boolean = false;

  //
  selectedTicket: VDVTicketModel = {
    id: 'iss:REPLACE_ME_HOLDER',
    areaOfValidity: {
      provider: 'DVB',
      area: ['Dresden']
    },
    brand: 'Monatskarte',
    category: 'Verbundnahverkehr',
    class: '2. Klasse',
    passengerType: 'Erwachsener',
    priceLevel: 0,
    ticketToken: "e832831200008c80177d760417ac40de200040fe2000852eda1100000000000020020019f002bc07019A93dc0b0417ac0000000a000a0000db0c0019790521413365405233728911177d100000177d40db75a0d400000017ac8a009a040000006f91010199070000006f10f447",
    totalPrice: {
      price: 0,
      priceCurrency: 'EUR',
      valueAddedTaxIncluded: true
    },
    vat: '19%',
    isProtectedByCRE: true
  };

  evidence: EvidenceModel[] = []

  form = new FormGroup({
    ticketType: new FormControl(this.ticketTypes),
    ticketTarifAmount: new FormControl(),
    firstTarifZone: new FormControl(this.zones),
    secondTarifZone: new FormControl(this.zones)
  });

  private subscription: any;



  // list of footer buttons
  footerButtons = [
    "Zurück",
    "Jetzt kaufen",
    "Konto"
  ];
  showQRCode: boolean = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private dataService: DataService,
    private createMessageService: CreateMessageService,
    private localDataStorageService: LocalDataStorageService,
    private router: Router,
    private envService: EnvService,
    private csb: CredentialBuilderService,
    private pgs: PurposeGeneratorService,
  ) { }

  // get selected favourite ticket from router-parameters
  ngOnInit(): void {
    this.sessionId = crypto.randomUUID();
    this.localDataStorageService.setData("sessionId", this.sessionId);
    this.subscription = this.activatedRoute.params.subscribe(params => {
      // fetch selected preset
      const paramId = +params['id'];
      const ticket = ticketPreSet.find(ticket => (ticket.id === paramId));
      if (ticket !== undefined) {
        this.selected.id = ticket.id;
        this.selected.message = ticket.message;
        this.selected.price = {
          name: ticket.price.name,
          value: ticket.price.value
        };
        this.selected.priceLevel = ticket.priceLevel;
        this.selected.priceLevelNo = ticket.priceLevelNo;
        this.selected.requiresCredentials = ticket.requiresCredentials;
        this.selected.zone = ticket.zone;
      }
      // update ticket type
      this.ticketTypes.forEach(item => {
        item.selected = (this.selected.price.name === item.name);
      });

      if (this.selected.price.name === TicketType.DEUTSCHLANDTICKET) {
        this.hasPriceLevel = false;
        this.hasZone = false;
        this.secondZone = false;
        this.firstZone = false;
        this.tType = TicketType.DEUTSCHLANDTICKET;
        this.selectedZones[0].selected = false;
        this.buttonText = "Deutschlandticket kaufen";
        this.requestPresentation = ['SEPADirectDebitMandate', 'KommPassLichtbild']
      }
      else if (this.selected.price.name === TicketType.EINZELFAHRT) {
        this.buttonText = "Einzelfahrt kaufen";
        this.tType = TicketType.EINZELFAHRT;
        this.hasPriceLevel = true;
        this.firstZone = true;
        this.requestPresentation = ['SEPADirectDebitMandate'];
        this.zones.forEach(tzone => {
          if (tzone.name === this.selected.zone[0]) {
            tzone.selected = true;
          }
        });
        this.selectedZones[0].name = this.selected.zone[0];
        this.selectedZones[0].selected = true;
        // update Preisstufe
        this.priceLevel.forEach(item => {
          item.selected = (this.selected.priceLevel === item.name);
        })
      } else {
        this.tType = TicketType.TAGESKARTE;
        this.requestPresentation = ['SEPADirectDebitMandate']
        this.buttonText = "Tageskarte kaufen";
        this.hasPriceLevel = true;
        this.firstZone = true;
        this.zones.forEach(tzone => {
          if (tzone.name === this.selected.zone[0]) {
            tzone.selected = true;
          }
        });
        this.selectedZones[0].name = this.selected.zone[0];
        this.selectedZones[0].selected = true;
      }
      this.updateAllZones();
      this.setPriceLevel(this.zones, this.selectedZones);
      this.updateSelectedTicket(this.tType);
    });

    // end: selection of ticket using the route param
  }

  isValidCredentialType(value: any): value is CredentialType {
    return Object.values(CredentialType).includes(value)
  }

  expandRequestPresentationByPurpose() {
    this.requestPresentation.forEach((cType: string, index: number) => {
      let credentialTypeObject: CredentialType;
      let additionalPurposeContentString: string = this.selectedTicket.brand + ', ' + this.selectedTicket.totalPrice.price + ' ' + this.selectedTicket.totalPrice.priceCurrency;
      if (this.tType === TicketType.DEUTSCHLANDTICKET) {
        credentialTypeObject = CredentialType.VDVTicketDticket;
        additionalPurposeContentString += ", Wiederkehrende Zahlung: Ja, "
      } else {
        credentialTypeObject = CredentialType.VDVTicketTicket
      }
      let purposeString: string = this.pgs.getPurposeString(cType, credentialTypeObject, additionalPurposeContentString)
      let obj = {
        [cType]: {
          'purpose': purposeString
        }
      }
      this.purposeObjectArray.push(obj);
      this.localDataStorageService.setData("purposeObjectArray", this.purposeObjectArray)
    })
  }

  onChanges(changes: SimpleChanges) {
    // updating the change of tickets
    if (changes['selectedTicket'].currentValue !== undefined
      && changes['selectedTicket'].currentValue !== null) {
      this.selectedTicket = changes['selectedTicket'].currentValue;
    }
  }

  // TODO Fix view
  private updateInfo(selectedTicket: Ticket): string {
    return "DVB/VVO-" + selectedTicket.price.name +
      " gültig vom " + this.htmlValidFrom +
      " bis " + this.htmlValidUntil;
  }

  /**
   * to format current date time to dd.MM.yyyy HH:mm:ss
   */
  private formatDateTime(months: number, days: number, hours: number, am4: boolean) {
    const currentDateTime = new Date();
    const nextDateTime = new Date(currentDateTime);
    nextDateTime.setMonth(nextDateTime.getMonth() + months);
    nextDateTime.setDate(nextDateTime.getDate() + days);
    if (am4) { nextDateTime.setHours(4, 0, 0); }
    else { nextDateTime.setHours(nextDateTime.getHours() + hours, nextDateTime.getMinutes(), 0); }

    this.htmlValidFrom = currentDateTime.toLocaleDateString('de-DE', { year: 'numeric', month: 'numeric', day: 'numeric' }) + ' ' +
      currentDateTime.toLocaleTimeString('de-DE') + ' Uhr';
    this.htmlValidUntil = nextDateTime.toLocaleDateString('de-DE', { year: 'numeric', month: 'numeric', day: 'numeric' }) + ' ' +
      nextDateTime.toLocaleTimeString('de-DE') + ' Uhr';
  }

  /**
   * to handle change of tickets using event value
   * @param event
   */
  changeTicket(event: any) {
    this.tType = event.target.value;

    this.ticketTypes.forEach(item => {
      item.selected = (item.name === this.tType);
    });

    if (this.tType === TicketType.DEUTSCHLANDTICKET) {
      this.hasPriceLevel = false;
      this.firstZone = false;
      this.secondZone = false;
      this.buttonText = "Deutschlandticket kaufen"
      this.selectedZones[0].name = "";
      this.selectedZones[0].selected = false;
      this.selectedZones[1].name = "";
      this.selectedZones[1].selected = false;
    }
    else {
      this.buttonText = "Ticket kaufen";
      this.hasPriceLevel = true;
      this.firstZone = true;
      if (!this.selectedZones[0].selected) {
        this.selectedZones[0].name = "Dresden";
        this.selectedZones[0].selected = true;
      }
    }
    this.updateAllZones();
    this.setPriceLevel(this.zones, this.selectedZones);
    this.updateSelectedTicket(this.tType);
  }

  changeTarifZoneAmount(event: any) {
    this.tarifZoneAmount = event.target.value;
    if (this.tarifZoneAmount == 1) {
      this.firstZone = true;
      this.secondZone = false;
      this.selectedZones[1].selected = false;
      this.selectedZones[1].name = "";
    } else if (this.tarifZoneAmount == 2) {
      this.firstZone = true;
      this.secondZone = true;
      for (let i = 0; i < this.zones.length; i++) {
        if (this.zones[i].name !== this.selectedZones[0].name && this.zones[i].name !== "Verbund") {
          this.selectedZones[1].selected = true;
          this.selectedZones[1].name = this.zones[i].name;
          break;
        }
      }
    }
    this.updateAllZones();
    this.setPriceLevel(this.zones, this.selectedZones);

    this.updateSelectedTicket(this.tType);
  }

  //user changes tariff zone
  changeZone(zone: any, zoneIndex: number) {
    const currentZone = { name: zone.target.value, selected: true };
    if (currentZone.name === "Verbund") {
      this.secondZone = false;
      this.selectedZones[1].name = "";
      this.selectedZones[1].selected = false;
    }
    this.selectedZones[zoneIndex] = currentZone;
    this.updateAllZones();
    this.setPriceLevel(this.zones, this.selectedZones);
    this.updateSelectedTicket(this.tType);

  }

  //update global variable zone based on multi-zone-variable
  updateAllZones(): void {
    this.zones.forEach(tzone => {
      this.selectedZones.forEach((sZone, index) => {
        if (index === 0) {
          if (tzone.name === sZone.name && sZone.selected) {
            tzone.selected = true;
          } else {
            tzone.selected = false;
          }
        } else {
          if (tzone.name === sZone.name && sZone.selected) {
            tzone.selected = true;
          } else if (!(tzone.name === this.selectedZones[index - 1].name)) {
            tzone.selected = false;
          }
        }
      });
    })
  }

  changeDiscount(event: any) {
    let value = event.target.value;
    if (value === 1) {
      this.discount = false;
    } else {
      this.discount = true;
    }
  }

  // set price-levels based on tarifzones input
  setPriceLevel(tzones: { name: string, selected: boolean }[], selectedTZones: { name: string, selected: boolean }[]) {
    if (selectedTZones[0].selected) {
      if (!selectedTZones[1].selected) {
        if (tzones[0].selected) {
          this.priceLevel.find(element => {
            element.selected = element.name === "A1";
          });
        } else if (tzones[3].selected) {
          this.priceLevel.find(element => {
            element.selected = element.name === "D";
          });
        } else {
          this.priceLevel.find(element => {
            element.selected = element.name === "A";
          })
        }
      } else {
        this.priceLevel.find(element => {
          element.selected = element.name === "B";
        })
      }
    } else {
      this.priceLevel.forEach(pLvl => {
        pLvl.selected = false;
      })
    }
  }

  // user chooses to buy ticket
  onBuyNow(): void {
    if (this.tType === TicketType.DEUTSCHLANDTICKET) {
      this.requestPresentation = ['SEPADirectDebitMandate', 'KommPassLichtbild']
    } else {
      this.requestPresentation = ['SEPADirectDebitMandate']
    }
    this.showBiometricPhoto = false;
    this.updateAllZones();
    this.setPriceLevel(this.zones, this.selectedZones);
    if (!this.isUpdatedTicket) {
      this.updateSelectedTicket(this.tType)
    }
    this.localDataStorageService.setData("navigationalFragment", "ticketshop")
    this.issueCredential();
  }

  processReceivedVC(credential: VerifiableCredential1xModel) {
    let vcType = this.evaluateForm(credential)
    if (vcType.length > 0) {
      let evidence: EvidenceModel = {
        docId: (' ' + credential.id).slice(1),
        documentPresence: 'Digital',
        evidenceDocument: vcType,
        id: '__HOME_URL__/Verification/' + crypto.randomUUID(),
        subjectPresence: 'Physical',
        type: ['DocumentVerification'],
        verifier: this.envService.env['HOME_URL'] + '/samples/KAPRION/SysActor/8506cbb4-c8d0-4b34-8f93-c5638893f0e2'
      };
      this.proofArray.push(vcType);
      this.evidence.push(evidence);
    }
  }

  evaluateForm(cred: VerifiableCredential1xModel): string {
    let foundType: string = "";
    this.requestPresentation.forEach(type => {
      if (cred.type.includes(type)) {
        foundType = type;
      }
    })
    return foundType
  }

  protected issueCredential() {
    this.selectedTicket.brand = this.selected.price.name;
    this.selectedTicket.totalPrice.price = this.selected.price.value;
    this.selectedTicket.priceLevel = this.selected.priceLevelNo;
    (<AreaOfValidity>this.selectedTicket.areaOfValidity).area = this.selected.zone;
    this.expandRequestPresentationByPurpose();

    const credentialContext = this.envService.env['CS_URL'] + "/credentialSubject/v7";
    const id = this.envService.env['HOME_URL'] + "/samples/KAPRION/VDVTicket/" + this.selected.price.name + "/" + crypto.randomUUID();
    const idString = id.split("/");
    this.oobid = idString.length - 1;
    this.credential = this.csb
      .id(id)
      .addContext(credentialContext)
      .addType("VDVTicket")
      .credentialSubject(this.selectedTicket)
    const currentDateTime = new Date();
    const nextDateTime = new Date(currentDateTime);
    let issuer: Issuer = Issuer.getDVBIssuer()
    if (this.selected.price.name === TicketType.DEUTSCHLANDTICKET) {
      nextDateTime.setMonth(nextDateTime.getMonth() + 1);
      nextDateTime.setHours(0, 0, 0);
      this.credential.evidence(this.evidence);
      //issuer = Issuer.getDticketIssuer()
    }
    else if (this.selected.price.name === TicketType.TAGESKARTE) {
      nextDateTime.setDate(nextDateTime.getDate() + 1);
      nextDateTime.setHours(4, 0, 0);
      this.credential.evidence(this.evidence)
    }
    else {
      nextDateTime.setHours(nextDateTime.getHours() + 1, nextDateTime.getMinutes() + 30, 0);
      this.credential.evidence(this.evidence)
    }

    this.credential.issuanceDate(currentDateTime.toISOString());
    this.credential.expirationDate(nextDateTime.toISOString());

    this.localDataStorageService.setData("credentialArray", [this.credential.build()]);
    this.localDataStorageService.setData("ticketIdArray", [id]);
    this.localDataStorageService.setData("taskType", "cs.icp.split.offerCredential");
    this.localDataStorageService.setData("requestPresentation", this.requestPresentation);
    let credential = this.csb
      .id(id)
      .addContext(credentialContext)
      .addType("VDVTicket")
      .issuanceDate()
      .expirationDate(this.csb.getDateTime(true, 1))
      .credentialSubject(this.selectedTicket)
      .build();
    this.credentialArray.push(credential);
    this.localDataStorageService.setData("credentialArray", this.credentialArray);

    const message = this.createMessageService.prepareMessageV3(Issuer.getDVBIssuer(), true, true, false).then(msg => {
      this.showQRCode = true;
      this.isRequestPresentation = false;
      this.appUrl = this.envService.env['VCISS_URL'] + "/issue" +
        "?_oobid=" + this.localDataStorageService.getData("oobid") +
        "&credentialProviderLink=" + this.envService.env['VCISS_URL'] + "/issuance";
      if (msg instanceof MessageContainer) {
        if (msg.getMessages().length < msg.getTaskAmount()) {
          msg.setTaskAmount(msg.getMessages().length)
        }
        msg = JSON.stringify(msg, (key, value) => {
          if (value === undefined || value === null) {
            return undefined;
          }
          // Filtering null and undefined values from arrays
          if (Array.isArray(value)) {
            return value.filter((item) => item !== null && item !== undefined);
          }
          return value;
        });

        this.dataService.send(msg, this.envService.env['VCISS_URL'] + '/initSession', true).then((response: any) => {
          if (response['type'] === "de.kaprion.icp.s2p.issueCredential.req") {
            this.showQRCode = false;
            let attach: any[] = []
            if (response['credentialApplication'] !== undefined) {
              if (response['credentialApplication']['verifiableCredential'] !== undefined) {
                attach = response['credentialApplication']['verifiableCredential'];
                attach.forEach((credential: any) => {
                  this.requestPresentation.forEach(requested => {
                      this.processReceivedVC(<VerifiableCredential1xModel>credential);
                  })
                });
                if (this.proofArray.length >= this.requestPresentation.length/2) {
                  this.isIssuance = true;
                  this.statusOk = true;
                  this.isWaitingForResponse = true;
                  this.displayObject = <Object>this.selectedTicket;
                  let finalCredential = this.csb
                    .id(id)
                    .addContext(credentialContext)
                    .addType("VDVTicket")
                    .issuanceDate()
                    .expirationDate(this.csb.getDateTime(true, 1))
                    .credentialSubject(this.selectedTicket)
                    .evidence(this.evidence)
                    .build();
                  this.localDataStorageService.setData("credentialArray", [finalCredential]);
                  this.localDataStorageService.setData("taskType", "cs.icp.split.issueCredential");
                  let finalMessage = this.createMessageService.prepareMessageV3(Issuer.getLHDIssuer(), true, true, false).then(finalMsg => {
                    if (finalMsg instanceof MessageContainer) {
                      finalMsg = JSON.stringify(finalMsg, (key, value) => {
                        if (value === undefined || value === null) {
                          return undefined;
                        }
                        // Filtering null and undefined values from arrays
                        if (Array.isArray(value)) {
                          return value.filter((item) => item !== null && item !== undefined);
                        }
                        return value;
                      });
                      this.dataService.send(finalMsg, this.envService.env['VCISS_URL'] + '/initSession').then((response: any) => {
                        this.messageText = "Ihr Nachweis wurde erfolgreich ausgestellt.";
                        this.messageArray.push(this.messageText);
                        //this.localDataStorageService.resetAllGlobals();
                        if (response['ok']) {
                          this.statusOk = true;
                          this.isIssuance = false;
                        } else {
                          this.messageText = "Nachweise konnten nicht verarbeitet werden: Status " + response['status'];
                          this.messageArray.push(this.messageText);
                          this.statusOk = false;
                        }
                      });
                    }
                  })
                } else {
                  this.alertMessage = "Vorgelegte Nachweise erfüllen nicht die Anforderung zur Ausstellung des VDV-Tickets."
                  this.hintMessage = "Folgende Nachweise erfüllen nicht die Anforderungen: " + this.checkEntry();
                  let dialogList = document.getElementById("dialog-box-alert");
                  (dialogList as any).showModal();
                  this.statusOk = false;
                }
              } else {
                this.statusOk = false;
                this.messageText = "Es wurden keine Nachweise präsentiert. Folgende Nachweise müssen präsentiert werden: "
                this.messageArray.push(this.messageText);
                this.requestPresentation.forEach(requestedPresentation => {
                  this.messageText += requestedPresentation + "\n"
                });
              }
            } else {
              this.statusOk = false;
              this.messageText = "Es wurden keine Nachweise präsentiert. Folgende Nachweise müssen präsentiert werden: "
                this.messageArray.push(this.messageText);
                this.requestPresentation.forEach(requestedPresentation => {
                  this.messageText += requestedPresentation + "\n"
                });
            }


          } else {
            this.messageText = "Nachweise konnten nicht verarbeitet werden: Status " + response['status'];
            this.messageArray.push(this.messageText);
            this.statusOk = false;
          }
          this.credentialArray = [];
          this.resetTicketInformation();
        }).catch((e) => {

          console.warn("Rejected: " + e);
        });
      }
    })
    this.showQRCode = true;
  }

  checkEntry() {
    return this.requestPresentation.filter(entry => !this.proofArray.includes(entry));
  }

  onClickBack() {
    if (this.tType === TicketType.DEUTSCHLANDTICKET && this.isBuyNow) {
      this.showBiometricPhoto = false;
      this.buttonText = "Deutschlandticket kaufen";
      this.router.navigate(["ticketselection/:14"]);
    } else {
      this.resetTicketInformation();
    }
  }

  resetTicketInformation(): void {
    this.isUpdatedTicket = false;
    this.proofArray = [];
    this.hasSecondZone = false;
    this.requestPresentation = ['SEPADirectDebitMandate'];
    this.purposeObjectArray = [];
    this.selectedZones = [{ name: "Dresden", selected: true }, { name: "", selected: false }];
    this.updateAllZones();
    this.setPriceLevel(this.zones, this.selectedZones);
    this.updateSelectedTicket(TicketType.EINZELFAHRT);
  }

  showLoginForm() {
    alert("Die Kontoverwaltung ist in dieser Demo nicht verfügbar");
  }

  updateSelectedTicket(ticketType: TicketType, isChangeTarifZoneAmount: boolean = false) {
    if (ticketType !== TicketType.DEUTSCHLANDTICKET) {
      this.requestPresentation = ['SEPADirectDebitMandate', 'KommPassLichtbild'];
      this.setPriceLevel(this.zones, this.selectedZones);
      this.isBuyNow = false;
    } else {
      this.requestPresentation = ['SEPADirectDebitMandate'];
      this.isBuyNow = false;
    }
    this.isUpdatedTicket = true;
    const priceLevels = this.priceLevel.find(element => element.selected === true);
    let ticketSelection: Ticket | undefined;
    if (priceLevels !== undefined) {
      const selectedZones = this.zones.filter(element => element.selected === true);
      const zones: string[] = [];
      selectedZones.forEach(sZone => {
        zones.push(sZone.name);
      })
      ticketSelection = this.getTicketFromPreset(ticketType, priceLevels.name, zones, isChangeTarifZoneAmount);
    } else {
      ticketSelection = ticketPreSet.find(element => element.price.name === TicketType.DEUTSCHLANDTICKET);
    }
    if (ticketSelection !== undefined) {
      this.selected.id = ticketSelection.id;
      this.selected.message = ticketSelection.message;
      this.selected.priceLevel = ticketSelection.priceLevel;
      this.selected.priceLevelNo = ticketSelection.priceLevelNo;
      this.selected.requiresCredentials = ticketSelection.requiresCredentials;
      this.selected.price.value = ticketSelection.price.value;
      this.selected.price.name = ticketType;
      this.selected.zone = ticketSelection.zone;
    }
    if (ticketType === TicketType.DEUTSCHLANDTICKET) {
      this.formatDateTime(1, 0, 0, false)
    } else if (ticketType === TicketType.EINZELFAHRT) {
      this.formatDateTime(0, 0, 1, false)
    } else {
      this.formatDateTime(0, 1, 0, true)
    }
    this.htmlInfo = this.updateInfo(this.selected);
    this.htmlMessage = this.selected.message;
    this.htmlPrice = this.formatter.format(this.selected.price.value);
  }

  //get ticket from ticketPreSet
  getTicketFromPreset(selectedTicketType: string, selectedPriceLevel: string, selectedZones: string[], isChangeTarifZoneAmount: boolean = false): Ticket {
    let selectedTicket: Ticket | undefined | null = undefined;

    for (let i = 0; i < ticketPreSet.length; i++) {
      if (ticketPreSet[i].price.name === selectedTicketType && ticketPreSet[i].priceLevel === selectedPriceLevel) {
        if (selectedZones.length === 1 && ticketPreSet[i].zone.length === 1) {
          if (ticketPreSet[i].zone[0] === selectedZones[0]) {
            selectedTicket = ticketPreSet[i];
            break;
          } else {
            selectedTicket = null;
          }
        } else if (selectedZones.length === 2 && ticketPreSet[i].zone.length === 2) {
          if ((selectedZones.includes(ticketPreSet[i].zone[0]) && selectedZones.includes(ticketPreSet[i].zone[1]))) {
            selectedTicket = ticketPreSet[i];
            break;
          } else {
            selectedTicket = undefined;
          }
        }
      }
    }

    //set default ticket: should only happen when resetting ticketinformation
    // TODO: remove / find clean solution
    if (selectedTicket === undefined || selectedTicket === null) {
      selectedTicket = ticketPreSet[0];
    }
    return selectedTicket
  }

  addToFavorites() {
    this.localDataStorageService.setData("additionalFavorite", this.selected.id);
  }

  OnDestroy() {
    this.subscription.unsubscribe();
  }

  onDialogBack() {
    let dialogList = document.getElementsByTagName("dialog");
    (dialogList[0] as any).close();
    this.sessionId = this.localDataStorageService.getData("sessionId");
    this.selectedTicket = {
      id: 'iss:REPLACE_ME_HOLDER',
      areaOfValidity: {
        provider: 'DVB',
        area: ['Dresden']
      },
      brand: 'Monatskarte',
      category: 'Verbundnahverkehr',
      class: '2. Klasse',
      passengerType: 'Erwachsener',
      priceLevel: 0,
      ticketToken: "e832831200008c80177d760417ac40de200040fe2000852eda1100000000000020020019f002bc07019A93dc0b0417ac0000000a000a0000db0c0019790521413365405233728911177d100000177d40db75a0d400000017ac8a009a040000006f91010199070000006f10f447",
      totalPrice: {
        price: 0,
        priceCurrency: 'EUR',
        valueAddedTaxIncluded: true
      },
      vat: '19%',
      isProtectedByCRE: true
    };;
    this.credentialArray = [];
    this.appUrl = "";
    this.showQRCode = false;
  }
}

