import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-toaster',
  templateUrl: './toaster.component.html',
  styleUrls: ['./toaster.component.scss']
})
export class ToasterComponent implements OnInit {

  @Input() messageText: string = "";
  @Input() readingTime: number = 5000;
  @Input() status: boolean = false;

  constructor(private router: Router) { }

  ngOnInit(): void {
    this.readingTime = (this.messageText.split(" ").length/100)*60*1000; // in ms
    if (this.readingTime < 5000) {
      this.readingTime = 5000;
    }
    setTimeout(() => {
      this.messageText = "";
      this.router.navigate(['home'])
    }, this.readingTime)
  }
}
