import { CredentialManifest } from "../credential-manifest.model";

export class IssuanceOfferCredential {
    type: string; //issuance for now possibly validation
    oobid: string;
    manifest: Object; //outputdescriptor u.a
    c: {[key: string]: any}; //credential to be issued in case of KDK
    ldDoc: {[key: string]: any}[];
    cSchema: {[key: string]: any}; //schema
    isPaid: boolean;
    isFree: boolean;
    version: string;

    constructor(oobid: string, taskIdentifier: string, public cManifest: CredentialManifest, credentialObjectArr: {[key: string]: any}[], ldDocument: {[key: string]: any}[], credentialSchemaObjArr: {[key: string]: any}[], paid: boolean, isFree: boolean) {
        this.type = taskIdentifier;
        this.oobid = oobid;
        this.manifest =cManifest;
        this.c = credentialObjectArr;
        this.ldDoc = ldDocument;
        this.cSchema = credentialSchemaObjArr;
        this.isPaid = paid;
        this.isFree = isFree;
        this.version = "0.10.0"
    }

    getType() {
        return this.type;
    }

    getOobid() {
        return this.oobid;
    }

    getManifest(){
        return this.manifest;
    }

    getLdDoc() {
        return this.ldDoc;
    }

    getCredentialSchema() {
        return this.cSchema;
    }

    getIsPaid() {
        return this.isPaid;
    }

    getIsFree() {
        return this.isFree;
    }

    toJSON() {
        return {
            type: this.type,
            oobid: this.oobid,
            manifest: this.manifest,
            c: this.c,
            ldDoc: this.ldDoc,
            cSchema: this.cSchema,
            isPaid: this.isPaid,
            isFree: this.isFree
        }
      }

}
