// service to handale request to server side

import { HttpErrorResponse } from '@angular/common/http';
//import { tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { LocalDataStorageService } from './local-data-storage.service';
import { EnvService } from 'src/environments/environment';

@Injectable()
export class DataService {

  pthid: string = '';
  senderDid: string = '';
  media_type: string = 'application/json';

  promiseArray: Promise<any>[] = [];
  hasReachedTimeoutForResponse = false;
  private _blnStatusSuccess: boolean[] = [];
  private _statusMessageArray: Object[] = [];

  constructor(private localDataStorageService: LocalDataStorageService, private envService: EnvService) {
  }

  /**
   * to send request to server side
   * @param data
   * @returns status
   */

  get blnStatusSuccess() {
    return this._blnStatusSuccess;
  }

  get(url: string) {
    return new Promise<any>((resolve, reject) => {

      const options = {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'access-control-allow-origin': '*'
        },
        method: 'GET',
      };
      fetch(url, options).then(response => {
        if (response.ok) {
          if (response.body !== undefined && response.body instanceof ReadableStream) {
            return response.json()
          } else {
            return response;
          }
        } else if (response.status === 401 || response.status === 404) {
          return response.status;
        } else {
          return response;
        }
      }).then(resp => {
        resolve(resp);
      }).catch((e: HttpErrorResponse) => {
        reject(e)
      })
    })
  }

  dummyGet(url: string) {
    return new Promise<any>((resolve, reject) => {
      let BRIDGE_DID = "did:key:z6MknCGsUC4cU9V1GLCpnhwzpJd78Brc3YsYyeobTBxQXa9Y";
      let BACKEND_URL = "http://localhost:4200/DatabaseController/api/presentCredential"
      let UUID = crypto.randomUUID();
      let responseUrl = "openid-vc://?client_id="+ BRIDGE_DID +"?request_uri="+ BACKEND_URL +"/api/presentCredential%3Flogin_id="+ UUID
      let wrappedObject = {
        url: responseUrl,
        sid: crypto.randomUUID()
      }
      //let rStream = new ReadableStream()
      setTimeout(() => {
        resolve(wrappedObject);
      },3000)
    })
  }

  // TODO modify function that both situations can be handled
  send(data: any, urlString: string, isResponseProcessed = false): Promise<any> {
    return new Promise((resolve, reject) => {

      if (data !== undefined) {
        data = this.replacePlaceHolder(data);

        const messagePromise = new Promise((resolve, reject) => {
          const options = {
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'access-control-allow-origin': '*'
            },
            method: 'POST',
            body: data
          };
          fetch(urlString, options).then(response => {
            if (response.ok) {
              if (isResponseProcessed) {
                resolve(response.json());
              } else {
                resolve(response);
              }
            }
          }).catch((e: HttpErrorResponse) => {
            if (e === undefined) {
              //console.warn('No answer from control-service received. Resolving ...');
              resolve(true);
              return true;
            } else if (e.error === undefined || e.status === undefined) {
              alert('Netzwerkfehler: Zieladresse nicht erreichbar, Ressourcen nicht abrufbar: ' + e);
              reject(e);
              return (e);
            } else {
              console.log(e.status + ': ' + e.statusText);
              alert('Etwas hat nicht funktioniert: ' + e.error);
              reject(e.status);
              return e.status;
            }

          });
        });
        resolve(messagePromise);
      } else {
        reject('error on promise');
        //     }
        // });
      }
    });
  }

  sendExpectJSON(data: any, urlString: string): Promise<any> {
    return new Promise((resolve, reject) => {
      if (data !== undefined) {
        data = this.replacePlaceHolder(data);

        const messagePromise = new Promise((resolve, reject) => {
          const options = {
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'access-control-allow-origin': '*'
            },
            method: 'POST',
            body: data
          };
          fetch(urlString, options).then(response => {
            if (response.body !== undefined && response.body instanceof ReadableStream) {
              return response.json()
            } else {
              return response
            }
          }).then(response => {
            resolve(response);
          })
            .catch((e: HttpErrorResponse) => {
              //console.log(e);
              // resolving e === undefined for tests
              if (e === undefined) {
                //console.warn('Keine Antwort vom Server erhalten');
                resolve(true);
                return true;
              } else if (e.error === undefined || e.status === undefined) {
                alert('Netzwerkfehler: Zieladresse nicht erreichbar, Ressourcen nicht abrufbar: ' + e);
                reject(e);
                return (e);
              } else {
                alert('Etwas hat nicht funktioniert: ' + e.error);
                reject(e.status);
                return e.status;
              }
            });
        });
        resolve(messagePromise);
      } else {
        reject('error on promise');
      }
    });
  }

  private replacePlaceHolder(data: any): any {
    let homeURL = this.envService.env['HOME_URL'];
    let tmp = data.replaceAll('__HOME_URL__', homeURL); // DEBUG procedure, reduce these lines in production to a single line
    //console.log('Data getting send: ' + tmp);           // x
    return tmp;                                         // x
  }

  getRequestMethod(method: string, url: string): Promise<any> {
    return new Promise((resolve, reject) => {
      let xmlHttpReq = new XMLHttpRequest();
      xmlHttpReq.open(method, url);
      xmlHttpReq.onload = function () {
        if (xmlHttpReq.status >= 200 && xmlHttpReq.status < 300) {
          resolve(xmlHttpReq.response);
        } else {
          reject({
            'status': xmlHttpReq.status,
            'statusText': xmlHttpReq.statusText
          });
        }

        xmlHttpReq.onerror = () => {
          reject({
            'status': xmlHttpReq.status,
            'statusText': xmlHttpReq.statusText
          });
        };
      };
    });
  }

  retrieveFile(urlString: string) {
    return new Promise((resolve, reject) => {
      fetch(urlString).then(result => {
        resolve(result.json());
        //return result
      }).catch(err => {
        //console.log(err);
      });
    });
  }
}
