<ng-container *ngIf="!isLoggedIn">
  <div style="display: block;" class="vh-73">
    <div class="col-xl-6 col-lg-6 col-md-8 col-sm-10 mx-auto p-3 pt-3" *ngIf="showQrCode">
      <div class="card border-0" style="background-color: #00000000;">
        <!--<div class="card-body" id="content-box">-->
        <app-showqrcode class="qr-code" *ngIf="showQrCode" [verificationUrl]="appUrl"
          [isRequestPresentation]="isRequestPresentation" [isDresdenPass]="false"></app-showqrcode>
      </div>
    </div>
  </div>

  <dialog id="dialog-box">
    <h3>Möchten Sie die Eingabe Ihrer Daten beenden und zur Credentialauswahl zurückkehren?</h3>
    <div class="inline">
      <button (click)="onDialogCancel()" class="link-box-white">Nein</button>
      <button (click)="onDialogOk()" class="link-box-white">Ja</button>
    </div>
  </dialog>
  <dialog id="dialog-box-alert">
    <h3>Es ist ein Fehler aufgetreten</h3>
    <p>{{alertMessage}}</p>
    <p *ngIf="hintMessage.length > 0">{{hintMessage}}</p>
    <div class="inline">
      <button (click)="onDialogBack()" class="link-box-white">Ok</button>
    </div>
  </dialog>
  <dialog id="dialog-box-presentation-invalid">
    <h3>Die Präsentation des Bank-Verfügungscredentials war nicht erfolgreich.</h3>
    <p>Bitte versuchen Sie es erneut</p>
    <div class="inline">
      <button (click)="onDialogPresentationInvalid()" class="link-box-white">Ok</button>
    </div>
  </dialog>
  <ng-container *ngFor="let message of messageArray">
    <app-toaster *ngIf="message.length > 0" [messageText]="message" [status]="statusOk"></app-toaster>
  </ng-container>
</ng-container>
<ng-container *ngIf="isLoggedIn">
  <div class="sidenav">
    <div class="sidenav-button"><i class="fa-solid fa-house"></i><a href="#">Übersicht</a></div>
    <div class="sidenav-button"><i class="fa-solid fa-money-bill-transfer"></i><a href="#">Überweisungen</a>
      <div class="sidenav-sub">
        <div class="sidenav-button-sub"><i class="fa-solid fa-house"></i><a href="#">Neue Überweisung</a></div>
        <div class="sidenav-button-sub"><i class="fa-solid fa-house"></i><a href="#">Terminüberweisungen verwalten</a>
        </div>
        <div class="sidenav-button-sub"><i class="fa-solid fa-house"></i><a href="#">Vorlagen verwalten</a></div>
      </div>
    </div>
    <div class="sidenav-button"><i class="fa-solid fa-shop"></i><a href="#">Leistungen</a></div>
    <div class="sidenav-button"><i class="fa-solid fa-magnifying-glass-chart"></i><a href="#">Finanzanalyse</a></div>
    <div class="sidenav-sub">
      <div class="sidenav-button-sub"><i class="fa-solid fa-house"></i><a href="#">Budget</a></div>
      <div class="sidenav-button-sub"><i class="fa-solid fa-house"></i><a href="#">Einnahmen und Ausgaben</a></div>
      <div class="sidenav-button-sub"><i class="fa-solid fa-house"></i><a href="#">Trends</a></div>
    </div>
    <div class="sidenav-button"><i class="fa-solid fa-envelope"></i><a href="#">Postfach</a></div>
  </div>
  <div class="navbar">
    <div class="navbar-button"><i class="fa-solid fa-circle-question"></i><a href="#"></a>Hilfe</div>

    <div class="navbar-button"><i class="fa-solid fa-gear"></i><a href="#"></a>Einstellungen</div>
    <div class="navbar-button" style="margin-top:10px">
      <i class="fa-solid fa-right-from-bracket"></i><a href="#">Abmelden</a>
      <p style="font-size: 0.7em;">Abmeldung in {{remainingTime | async | date: 'mm:ss'}} Minuten.</p>
    </div>
  </div>
  <div class="container">
    <div class="main grid">
      <h4 style="padding-left: 1em;">Meine Konten</h4>
      <div class="card">
        <div class="row row-account-details">
          <div class="col">
            <div><i class="fa-regular fa-credit-card"></i></div>
          </div>
          <div class="col-10">
            <div class="col-account-details">
              <span class="pr"><b style="color: #ffffff">Privatkonto Comfort</b></span>
              <span *ngFor="let fragment of fragments">
                <ng-container *ngIf="fragments!.indexOf(fragment) > 2">
                  <b>{{fragment}} </b>
                </ng-container>
                <ng-container *ngIf="fragments!.indexOf(fragment) <= 2">
                  {{fragment}}
                </ng-container> </span>
              <span class="pl">{{owner}}</span>
              <div class="col-account-balance">{{balance | currency:'EUR':true}}</div>
            </div>
          </div>
          <div class="col">
            <i class="fa-solid fa-chevron-down"></i>
          </div>
        </div>
      </div>
      <h4 style="padding-left: 1em;"></h4>
      <div class="card">
        <div class="row row-account-details">
          <div class="col">
            <div><i class="fa-solid fa-plus"></i></div>
          </div>
          <div class="col-10">
            <div class="col-account-details">
              Bankverbindung hinzufügen
            </div>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="row row-account-details">
          <div class="col">
            <div><i class="fa-regular fa-credit-card"></i></div>
          </div>
          <div class="col-10">
            <div class="col-account-details">
              Letzte Transaktionen
            </div>
          </div>
          <div class="col">
            <i class="fa-solid fa-chevron-down"></i>
          </div>
        </div>
      </div>
    </div>
    <div class="spacer"></div>
  </div>
  <dialog *ngIf="isLogoutWarning" id="dialog-box">
    <h3>Sie werden in {{logoutWarningTime}} abgemeldet!</h3>
    <div class="inline">
      <button (click)="onDialogCancel()" class="link-box-white">Nicht abmelden</button>
      <button (click)="onDialogOk()" class="link-box-white">Abmelden</button>
    </div>
  </dialog>
  <ng-container *ngFor="let message of messageArray">
    <app-toaster *ngIf="message.length > 0" [messageText]="message" [status]="statusOk"></app-toaster>
  </ng-container>
</ng-container>