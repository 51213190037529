<div class="row">
    <div class="col">
        <div class="wrapper-progressBar">
            <ul class="progressBar">
                <li [ngClass]="{'active': step >= 1}">Person</li>
                <li [ngClass]="{'active': step >= 2}">Adresse</li>
                <li [ngClass]="{'active': step >= 3}">Geburtsurkunde</li>
                <li [ngClass]="{'active': step >= 4}">Altersnachweis</li>
                <li [ngClass]="{'active': step >= 5}">Biometrisches Photo</li>
                <!--<li [ngClass]="{'active': step >= 6 && hasSepa === true , 'deactivated': hasSepa === false}">Sepa Mandat
                </li>
                <li
                    [ngClass]="{'active': step >= 6 && hasDresdenPass === true, 'deactivated': hasDresdenPass === false}">
                    Dresden Pass</li>-->
                <li
                    [ngClass]="{'active': (hasDresdenPass && hasSepa && step === 8) || ((!hasDresdenPass && hasSepa || hasDresdenPass && !hasSepa) && step === 7) || (!hasDresdenPass && !hasSepa && step === 6)}">
                    Eingaben prüfen</li>
            </ul>
        </div>
    </div>
</div>