
<header>Validierung des Besuchernachweises</header>

<div class="main-wrapper container-fluid">
  <!-- BEGIN content box -->
  <div class="col-xl-6 col-lg-6 col-md-8 col-sm-10 mx-auto p-3 pt-3">
    <div class="card border-0 card_shadow">
      <div class="card-body">
        <div id="checkbox" class="form-check">
          <h4>An welchen Ständen waren Sie?</h4>
          <form>
            <input type="checkbox" id="dresden" name="dresden" value="dresden" class="form-check-input" [(ngModel)]="checkDresden" (change)="checkCheckBoxvalue($event)">
            <label for="dresden" class="form-check-label">Landeshauptstadt Dresden</label><br>
            <input type="checkbox" id="leipzig" name="leipzig" value="leipzig" class="form-check-input" [(ngModel)]="checkLeipzig" (change)="checkCheckBoxvalue($event)">
            <label for="leipzig" class="form-check-label">Stadt Leipzig</label><br>
            <input type="checkbox" id="eecc" name="eecc" value="eecc" class="form-check-input" [(ngModel)]="checkEECC" (change)="checkCheckBoxvalue($event)">
            <label for="eecc" class="form-check-label">EECC European EPC Competence Center</label><br>
            <input type="checkbox" id="hmw" name="hmw" value="hmw" class="form-check-input" [(ngModel)]="checkHMW" (change)="checkCheckBoxvalue($event)">
            <label for="hmw" class="form-check-label">Hochschule Mittweida</label><br>
            <input type="checkbox" id="fit" name="fit" value="fit" class="form-check-input" [(ngModel)]="checkFFIT" (change)="checkCheckBoxvalue($event)">
            <label for="fit" class="form-check-label">Fraunhofer FIT</label><br>
            <input type="checkbox" id="htwd" name="htwd" value="htwd" class="form-check-input" [(ngModel)]="checkHTWD" (change)="checkCheckBoxvalue($event)">
            <label for="htwd" class="form-check-label">Trustnet Café</label><br>
            <input type="checkbox" id="kaprion" name="kaprion" value="kaprion" class="form-check-input" [(ngModel)]="checkKT" (change)="checkCheckBoxvalue($event)">
            <label for="kaprion" class="form-check-label">KAPRION Technologies GmbH</label><br><br>
          </form>
        </div>
        <canvas class="showqrcode-icon" id="canvas"></canvas>
        <div id="response"></div>
      </div>
    </div>
  </div>
  <!-- END aside box -->

  <!-- BEGIN button wrapper -->
  <div class="flex-wrapper">
    <button type="submit" (click)="requestBesuchernachweis()" class="link-box-white">{{ buttonText }}</button>
  </div>
  <!-- END button wrapper -->
</div>
