<header class="ticketbuy-heading">Ticket kaufen</header>
<div class="main-wrapper container-fluid">
  <form [formGroup]="form" *ngIf="!showQRCode && proofArray.length === 0">
    <div class="col-xl-7 col-lg-7 col-md-8 col-sm-10 mx-auto p-3 pt-3">
      <div class="card border-0 card_shadow">
        <div class="card-body">
          <ng-container *ngIf="!isIssuance">
            <span class="ticketbuy-label">Ticketauswahl:</span>

            <select name="ticketType" (change)="changeTicket($event)" class="tickets form-select">
              <option *ngFor="let t of ticketTypes" [selected]="t.selected" [value]="t.name">{{t.name}}</option>
            </select>

            <span [ngClass]="this.hasPriceLevel === false ? 'ticketbuy-label none' : 'ticketbuy-label'">Anzahl der
              Tarifzonen:</span>

            <div [ngClass]="!this.hasPriceLevel ? 'pricelevel none' : 'pricelevel'">
              <select name="ticketTarifAmount" class="zones form-select" id="zoneSelect"
                (change)="changeTarifZoneAmount($event)">
                <option *ngFor="let amount of [1,2]" [value]="amount">{{amount}}
                  <span *ngIf="amount === 1"> Tarifzone</span>
                  <span *ngIf="amount > 1"> Tarifzonen</span>
                </option>
              </select>
            </div>

            <!--First zone-->
            <div [hidden]="!this.firstZone">
              <span class="ticketbuy-label">1. Tarifzone:</span>

              <select name="firstTarifZone" class="zones form-select" id="first-zone" (change)="changeZone($event, 0)">
                <option *ngFor="let z of zones" [selected]="z.selected && z.name === selectedZones[0].name"
                  [title]="this.secondZone ? 'Wählen Sie unter Anzahl der Tarifzonen: 1 Tarifzone um Verbund auswählen zu können' : ''"
                  [value]="z.name" [disabled]="this.secondZone && z.name === 'Verbund'"
                  [ngClass]="z.name === selectedZones[1].name ? 'no-display' : 'display'">
                  {{z.name}}
                </option>
              </select>
            </div>



            <!--Second Zone-->
            <div [hidden]="!this.secondZone">
              <span class="ticketbuy-label">2. Tarifzone:</span>

              <select name="secondTarifZone" class="zones form-select" id="second-zone"
                (change)="changeZone($event, 1)">
                <option *ngFor="let z of zones" [selected]="z.selected && z.name === selectedZones[1].name"
                  [ngClass]="z.name === 'Verbund' || z.name === selectedZones[0].name ? 'no-display' : 'display'"
                  [value]="z.name">{{z.name}}</option>
              </select>
            </div>
          </ng-container>

          <span class="ticketbuy-label selection">Ihr Ticket:</span>
          <div class="ticketbuy-ticket-type">
            <div class="ticketbuy-ticket-info">
              <span class="ticketbuy-ticket-info-content">{{htmlInfo}}</span>
            </div>
            <div class="ticketbuy-ticket-delete">
              <span>{{htmlPrice}}</span>
            </div>
            <div class="ticketbuy-ticket-star" (click)="addToFavorites()">
              <img [src]="imgURL + 'star-icon.png'" alt="Favoriticon" class="ticketbuy-star-icon">
            </div>
          </div>

          <span class="ticketbuy-label-message">{{htmlMessage}}</span>

        </div>
      </div>
    </div>

  </form>
  <div class="col-xl-6 col-lg-6 col-md-8 col-sm-10 mx-auto p-3 pt-3" *ngIf="isWaitingForResponse">
    <div class="card border-0 card_shadow">
      <div class="card-body">
        <h3 style="text-align: center;">Ihr Ticket wird nun ausgestellt.
        </h3>

      </div>
    </div>
  </div>
  <app-presentation *ngIf="proofArray.length === 2 || proofArray.length === 1"
    [proofArray]="proofArray"></app-presentation>
  <app-showqrcode *ngIf="showQRCode" [verificationUrl]="appUrl" class="vh-73"></app-showqrcode>
  <div class="flex-wrapper" style="margin: auto 0;">
    <ng-container *ngIf="true">
      <button *ngIf="!showQRCode && !isWaitingForResponse" (click)="onClickBack()"
        class="link-box-white">Zurück</button>
      <button *ngIf="!showQRCode && !isWaitingForResponse" (click)="onBuyNow()"
        [ngClass]="showQRCode ? 'link-box-white vh40': 'link-box-white'" name="next">{{buttonText}}
      </button>
      <button (click)="showLoginForm()" [disabled]="true" class="link-box-white" name="show-login"
        style="display: none;">Konto
      </button>
    </ng-container>
  </div>
</div>
<dialog id="dialog-box-alert">
  <h3>Es ist ein Fehler aufgetreten</h3>
  <p>{{alertMessage}}</p>
  <p *ngIf="hintMessage.length > 0">{{hintMessage}}</p>
  <div class="inline">
    <button (click)="onDialogBack()" class="link-box-white">Ok</button>
  </div>
</dialog>
<ng-container *ngFor="let message of messageArray">
  <app-toaster *ngIf="message.length > 0" [messageText]="message" [status]="statusOk"></app-toaster>
</ng-container>
<!-- Footer -->
<!--<app-footer [footerButtons]="footerButtons" [selectedTicket]="selectedTicket"></app-footer>-->