import { LOCALE_ID, NgModule, APP_INITIALIZER } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import * as de from '@angular/common/locales/de';
import { BrowserModule } from '@angular/platform-browser';
import { PathLocationStrategy, LocationStrategy } from '@angular/common';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { EnvService } from 'src/environments/environment';

import { GetLangPipe } from './pipe/get-lang.pipe';

import { AppComponent } from './app.component';
import { TicketshopComponent } from './components/ticketshop/ticketshop.component';
import { TicketBuyComponent } from './components/ticketbuy/ticketbuy.component';
import { FooterComponent } from './components/footer/footer.component';
import { ShowQrCodeComponent } from './components/showqrcode/showqrcode.component';
import { CredentialComponent } from './components/credential/credential.component';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { HttpClientTestingModule } from '@angular/common/http/testing';
import { Base64ImageDecodePipe } from './pipe/base64-image-decode.pipe';
import { HasTypePipe } from './pipe/has-type.pipe';
import { WebsocketService } from './services/websocket.service';
import { CreateMessageService } from 'src/app/services/create-message.service';
import { SchemaService } from 'src/app/services/schema.service';
import { DataService } from './services/data.service';
import { HomeComponent } from './components/home/home.component';
import { WohngeldbescheidComponent } from './components/wohngeldbescheid/wohngeldbescheid.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PartySelectorComponent } from './components/party-selector/party-selector.component';
import { DefaultCredentialComponent } from './components/default-credential/default-credential.component';
import { PrivacyPoliceComponent } from './components/privacy-police/privacy-police.component';
import { TermsConditionsComponent } from './components/terms-conditions/terms-conditions.component';
import { DresdenPassComponent } from './components/dresdenpass/dresden-pass.component';
import { SccVerificationComponent } from './components/scc-verification/scc-verification.component';
import { SepaMandatePresentationComponent } from './components/sepa-mandate-presentation/sepa-mandate-presentation.component';
import { PresentationComponent } from './components/presentation/presentation.component';
import { ToasterComponent } from './components/toaster/toaster.component';
import { EuBankAccountComponent } from './components/eu-bank-account/eu-bank-account.component';
import { EuBankLoginComponent } from './components/eu-bank-login/eu-bank-login.component';
import { BrowserAnimationsModule, provideAnimations } from '@angular/platform-browser/animations';
import { KommpassComponent } from './components/kommpass/kommpass.component';
import { WaiterComponent } from './components/waiter/waiter.component';
import { AppConfigService } from 'src/app-config.service';
import { MobileContractComponent } from './components/mobile-contract/mobile-contract.component';
import { NextbikeComponent } from './components/nextbike/nextbike.component';
import { DrivingLicenseComponent } from './components/driving-license/driving-license.component';
import { TeilautoComponent } from './components/teilauto/teilauto.component';
import { JungheinrichComponent } from './components/jungheinrich/jungheinrich.component';
import { MobileContractLoginComponent } from './components/mobile-contract-login/mobile-contract-login.component';

@NgModule({
  declarations: [
    AppComponent,
    TicketshopComponent,
    TicketBuyComponent,
    FooterComponent,
    ShowQrCodeComponent,
    CredentialComponent,
    ProgressBarComponent,
    GetLangPipe,
    Base64ImageDecodePipe,
    HasTypePipe,
    HomeComponent,
    WohngeldbescheidComponent,
    PartySelectorComponent,
    DefaultCredentialComponent,
    PrivacyPoliceComponent,
    TermsConditionsComponent,
    DresdenPassComponent,
    SccVerificationComponent,
    SepaMandatePresentationComponent,
    PresentationComponent,
    ToasterComponent,
    EuBankAccountComponent,
    EuBankLoginComponent,
    KommpassComponent,
    WaiterComponent,
    MobileContractComponent,
    NextbikeComponent,
    DrivingLicenseComponent,
    TeilautoComponent,
    JungheinrichComponent,
    MobileContractLoginComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    HttpClientModule,
    HttpClientTestingModule,
    NgbModule
  ],
  providers: [
    { provide: LocationStrategy, useClass: PathLocationStrategy },
    { provide: LOCALE_ID, useValue: 'de-DE' },
    { provide: APP_INITIALIZER,
      useFactory: initConfig,
      deps: [AppConfigService],
      multi: true,},
      AppConfigService,
    provideAnimations(),
    DataService,
    WebsocketService,
    CreateMessageService,
    SchemaService,
    EnvService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor() {
    registerLocaleData(de.default); //Then register the language
  }
}

export function initConfig(appConfig: AppConfigService) {
  return () => appConfig.loadConfig();
}
