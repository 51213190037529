import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'base64ImageDecode'
})
export class Base64ImageDecodePipe implements PipeTransform {

  constructor() {
  }

  transform(value: string): string {
    return value;
  }

}
