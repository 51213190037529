// component shows the bar code and link to play store to download, in case of missing wallet app
import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import * as qrcode from 'qrcode';
import { CreateMessageService } from 'src/app/services/create-message.service';
import { WebsocketService } from 'src/app/services/websocket.service';
import { EnvService, environment } from '../../../environments/environment';
import { LocalDataStorageService } from '../../services/local-data-storage.service';

@Component({
  selector: 'app-showqrcode',
  templateUrl: './showqrcode.component.html',
  styleUrls: ['./showqrcode.component.scss']
})
export class ShowQrCodeComponent implements OnInit {
  @Input() verificationUrl: string = "";
  @Input() isRequestPresentation: boolean = false;
  @Input() isDresdenPass: boolean = false;
  @Input() isStep: boolean = false; //reload the component if boolean switches state
  @Input() heading: string = "";

  credentialArray: {[key: string]: any}[] = [];
  sessionId: string = "";
  downloadLink: string = "";
  ticketIdArray: string[] = [];
  appUrl: string = "";
  step: number = 0;
  isMobile: boolean = false;

  imgURL = environment.imgURL;
  oobid: string;
  readonly userAgent: string;

  constructor(private localDataStorageService: LocalDataStorageService, private router: Router, private websocketService: WebsocketService, private createMessageService: CreateMessageService, private envService: EnvService) {
    this.ticketIdArray = this.localDataStorageService.getData("ticketIdArray");
    this.credentialArray = this.localDataStorageService.getData("credentialArray");
    this.oobid = this.localDataStorageService.getData("oobid");
    this.sessionId = this.localDataStorageService.getData("sessionId");
    this.userAgent = window.navigator.userAgent;
  }

  ngOnInit(): void {
        this.oobid = this.localDataStorageService.getData("oobid");
        if (this.oobid.length > 0 && this.verificationUrl.length === 0) {
          this.appUrl = this.envService.env['VCISS_URL'] + "/issue" + "?_oobid=" + this.oobid + "&credentialProviderLink=" + this.envService.env['VCISS_URL'] + "/issuance";
        } else if (this.oobid.length === 0 && this.verificationUrl.length === 0) {
          //console.error("oobid invalid")
        }
        if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(this.userAgent)){
          this.isMobile = true;
          this.distinguishUrls();
        } else {
          this.isMobile = false;
          this.distinguishUrls();
        }
      //}
  }

  ngOnChanges(simpleChanges: SimpleChanges) {
    if (this.oobid.length > 0 && this.verificationUrl.length === 0) {
      this.appUrl = this.envService.env['VCISS_URL'] + "/issue" + "?_oobid=" + this.oobid + "&credentialProviderLink=" + this.envService.env['VCISS_URL'] + "/issuance";
    }
    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(this.userAgent)){
      this.isMobile = true;
      this.distinguishUrls();
    } else {
      this.isMobile = false;
      this.distinguishUrls();
    }
  }

  distinguishUrls() {
    if (this.verificationUrl.length === 0) {
      this.generateQrCode(this.appUrl);
    } else {
      this.generateQrCode(this.verificationUrl);
    }
  }

  generateQrCode(url: string, _this = this) {
    let canvas = document.getElementById("canvas");
    qrcode.toCanvas(canvas, url, function (error) {
      if (error) {
        alert("QR-Code konnte nicht erzeugt werden. Bitte versuchen Sie es erneut.");
        _this.router.navigate(['home']);
      }
    });
  }

  /**
   * to redirect to the play store url
   */
  downloadApp() {
    this.downloadLink = this.appUrl + this.sessionId;

    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
      // true for mobile device
      window.open(this.downloadLink, "_blank");
    }else{
      // false for not mobile device
      alert("Bitte scannen Sie den QR-Code mit Ideal Wallet.");
    }
  }
}
