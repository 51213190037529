export class PresentationDefinition {
    id: string;
    //frame?:{[key: string]: any} | null;
    input_descriptors: {[key: string]: any}[];

    constructor(id: string, inputDesc: {[key: string]: any}[]) { //frame: {[key: string]: any} | null = null,
        this.id = id;
        //this.frame = frame; // not currently used
        this.input_descriptors = inputDesc;
    }
}
