// component allows to show a step by step progress
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss']
})
export class ProgressBarComponent implements OnInit, OnChanges {

  imgURL = environment.imgURL;

  @Input() step: number = 1;
  @Input() hasSepa: boolean = false;
  @Input() hasDresdenPass: boolean = false;
  @Input() isVdvTicket: boolean = false;

  constructor() {
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['hasSepa'] !== undefined) {
      if(changes['hasSepa'].currentValue !== changes['hasSepa'].previousValue) {
        this.hasSepa = changes['hasSepa'].currentValue;
      }
    }
    if (changes['hasDresdenPass'] !== undefined) {
      if(changes['hasDresdenPass'].currentValue !== changes['hasDresdenPass'].previousValue) {
        this.hasDresdenPass = changes['hasDresdenPass'].currentValue;
      }
    }
  }
}
