import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LocalDataStorageService } from 'src/app/services/local-data-storage.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  @Input() showPartySelector: boolean = true;
  credentialStartType: string = "";

  constructor(private router: Router,
    private localDataStorageService: LocalDataStorageService) { }


  ngOnInit(): void {
    this.localDataStorageService.setData("navigationalFragment", "");
  }

  click(credentialType: string): void {
    this.credentialStartType = credentialType;
    if (credentialType.includes("sccVisitorsCredential")) {
      this.router.navigate(["party-selector"]);
    }
  }
}
