import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {MessageContainer} from '../../models/messages/messageContainer.model';
import {CreateMessageService} from '../../services/create-message.service';
import {DataService} from '../../services/data.service';
import {LocalDataStorageService} from '../../services/local-data-storage.service';
import {WohngeldbescheidModel} from "../../models/kaprion/vc/wohngeldbescheid.model";
import {EnvService} from '../../../environments/environment';
import {CredentialBuilderService} from "../../services/credential-builder.service";
import {Issuer} from '../../models/issuer.model';

/**
 * WohngeldbescheidModel
 *
 * @author tehrlich
 * @version 2023-09-11
 */
@Component({
  selector: 'app-wohngeldbescheid',
  templateUrl: './wohngeldbescheid.component.html',
  styleUrls: ['./wohngeldbescheid.component.scss']
})
export class WohngeldbescheidComponent implements OnInit {

  sessionId: string = "";
  ticketIdArray: string[] = [];
  oobid: string = "";
  credentialArray: any[] = [];
  messageText: string = "";
  statusOk: boolean = false;

  /**
   * Credential subject variable with default values
   */
  credSubject: WohngeldbescheidModel = {
    id: "iss:REPLACE_ME_HOLDER",
    typ: "Wohngeld plus",
    erstantrag: true,
    einheit: "EUR",
    ihreAngaben: {
      bruttoEinkommen: 1500.0,
      bruttoMiete: 580.0,
      anzWohngeldBerechtigt: 3,
      kinderbetreuungskosten: 333.15,
      mietenstufe: 3
    },
    berechnung: {
      werbungskosten: 659.51,
      gesamtEinkommenNachAbzuegen: 840.49,
      berueksichtigungMiete: 580.0,
      betriebskostenAbzug: 149.0,
      heizkostenpauschale: 170.20
    },
    gesamtbetrag: 601.0,
    isProtectedByCRE: true
  };

  showQRCode: boolean = false;

  constructor(
    private dataService: DataService,
    private createMessageService: CreateMessageService,
    private localDataStorageService: LocalDataStorageService,
    private router: Router,
    private envService: EnvService,
    private csb: CredentialBuilderService) {
  }

  ngOnInit(): void {
    this.sessionId = crypto.randomUUID();
    this.localDataStorageService.setData("sessionId", this.sessionId)
  }

  /**
   * Returns an ISO 8601 date-time string
   * @param deltaYear
   */
  getDateTime(deltaYear: number = 0) {
    let date = new Date();
    if (deltaYear > 0) {
      date.setHours(0, 0, 0, 0); // reset hours, minutes, seconds and milliseconds
      date.setFullYear(date.getFullYear() + deltaYear);
    }
    return date.toISOString();
  }

  /**
   * Resets the credential to default values
   */
  resetCredential() {
    this.credSubject = {
      id: "iss:REPLACE_ME_HOLDER",
      typ: "Wohngeld plus",
      erstantrag: true,
      einheit: "EUR",
      ihreAngaben: {
        bruttoEinkommen: 1500.0,
        bruttoMiete: 580.0,
        anzWohngeldBerechtigt: 3,
        kinderbetreuungskosten: 333.15,
        mietenstufe: 3
      },
      berechnung: {
        werbungskosten: 659.51,
        gesamtEinkommenNachAbzuegen: 840.49,
        berueksichtigungMiete: 580.0,
        betriebskostenAbzug: 149.0,
        heizkostenpauschale: 170.20
      },
      gesamtbetrag: 601.0
      // isProtectedByCRE: true
    };
  }

  /**
   * Builds a WohngeldbescheidModel Credential
   * TODO("add ngForms")
   */
  fillCredential() {
    this.oobid = crypto.randomUUID();

    let credentialContext = this.envService.env['CS_URL'] + "/credentialSubject/v7";
    let id = this.envService.env['HOME_URL'] + "/samples/KAPRION/Wohngeldbescheid/" + this.oobid;

    let credential = this.csb
      .id(id)
      .addContext(credentialContext)
      .addType("Wohngeldbescheid")
      .issuanceDate()
      .expirationDate()
      .credentialSubject(this.credSubject)
      .build();

    this.ticketIdArray.push(id);
    this.credentialArray.push(credential);
  }

  /*
   * This function (or parts) shall be moved into a server controller class
   */
  onFinalSubmit() {
    this.fillCredential()
    this.localDataStorageService.setData("credentialArray", this.credentialArray);
    this.localDataStorageService.setData("ticketIdArray", this.ticketIdArray);
    this.localDataStorageService.setData("taskType", "de.kaprion.icp.s2p");

    let message = this.createMessageService.prepareMessage(Issuer.getEShopIssuer(), true, false, true).then((msg: any) => {
      if (msg instanceof MessageContainer) {
        if (msg.getMessages().length < msg.getTaskAmount()) {
          msg.setTaskAmount(msg.getMessages().length);
        }

        msg = JSON.stringify(msg, (key, value) => {
          if (value === undefined || value === null) {
            return undefined;
          }
          // Filtering null and undefined values from arrays
          if (Array.isArray(value)) {
            return value.filter((item) => item !== null && item !== undefined);
          }
          return value;
        });

        this.dataService.send(msg, this.envService.env['VCISS_URL'] + '/initSession').then(response => {
          if(response['ok']) {
            this.statusOk = true;
            this.messageText = "Ihr Wohngeld-Nachweis wurde erfolgreich ausgestellt";
          } else {
            this.messageText = "Nachweise konnten nicht verarbeitet werden: Status " + response['status'];
            this.statusOk = false;
          }
          setTimeout(() => {
            this.messageText = "";
          }, 3000)
          this.ticketIdArray = [];
          this.credentialArray = [];
          this.resetCredential();
        }).catch((e) => {

          console.warn("Rejected: " + e);
        });
      }
      this.localDataStorageService.setData("navigationalFragment", "/wohngeldbescheid");
      // this.router.navigate(['/qrcode/' + this.sessionId]);
      //this.localDataStorageService.setData("oobid", this.oobid);
      this.showQRCode = true;
    });
  }

  //abort-functionality | back-button
  onClickBack() {
    let dialogList = document.getElementsByTagName("dialog");
    (dialogList[0] as any).showModal();
  }

  // request confirmation
  onDialogCancel() {
    let dialogList = document.getElementsByTagName("dialog");
    (dialogList[0] as any).close();
  }

  onDialogOk() {
    let dialogList = document.getElementsByTagName("dialog");
    (dialogList[0] as any).close();
    this.localDataStorageService.setData("navigationalFragment", "");
    this.router.navigate(["home"]);
  }

}
