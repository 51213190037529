import { Injectable } from "@angular/core";
import { AppConfigService } from "src/app-config.service";
//import { DataService } from "src/app/services/data.service";

export interface Environment {
  CONFIGURATION: string, //build environment
  HOME_URL: string, // address home
  CS_URL: string, // address control-service
  VCISS_URL: string, // address control-service   
}

export let environment = {
  imgURL: 'assets/images/',
  timestamp: "2024-10-11 08:50",
  production: false,
  assets: 'assets/',
}

@Injectable({ providedIn: 'root' })
export class EnvService {
  private _env: { [key: string]: any } = environment;
  private _apiUrl: string = ""

  get env(): { [key: string]: any } {
    return this._env
  }

  set env(system: any) {
    this._env = (environment as { [key: string]: any })[system]
  }

  get apiUrl(): string {
    return this._apiUrl
  }

  constructor(private appConfigService: AppConfigService) {
    this.init();
  }

  init(){
      //this.setEnvVariables()
        this.setEnvVariables(this.appConfigService.getConfig())
  }

  private setEnvVariables(conf: Environment) {
    let config = conf.CONFIGURATION as keyof typeof environment
      environment = { ...environment,
        [config]: {
          HOME_URL: conf.HOME_URL,
          CS_URL: conf.CS_URL,
          VCISS_URL: conf.VCISS_URL,
        }

      }
      this.env = config
      // Object.defineProperties(environment[config], {
      //   HOME_URL: { enumerable: true, writable: true, value: env.HOME_URL },
      //   CS_URL: { enumerable: true, writable: true, value: env.CS_URL },
      //   VCISS_URL: { enumerable: true, writable: true, value: env.VCISS_URL },
      // })
  }

  private getEnvJson(): Promise<Environment> {
    return new Promise((resolve, reject) => {
      fetch(window.location.origin + "/config/config.json").then((response: Response) => {
        if (response.ok) {
          let responseBody = response.json().then(body => {
            if (this.isEnvironment(body)) {
              resolve(body);
            } else {
              reject("Environment not valid.")
            }
            return body
          }).catch(() => {
            return undefined
          })
          return responseBody
        } else {
          return undefined
        }
      })
    })
  }

  isEnvironment(obj: Environment | any): obj is Environment {
    if ((obj as Environment).CONFIGURATION !== undefined
      && (obj as Environment).HOME_URL !== undefined
      && (obj as Environment).CS_URL !== undefined
      && (obj as Environment).VCISS_URL !== undefined) {
      return true;
    }
    else {
      return false;
    }
  }
}
