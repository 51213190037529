import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { LocalDataStorageService } from 'src/app/services/local-data-storage.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-party-selector',
  templateUrl: './party-selector.component.html',
  styleUrls: ['./party-selector.component.scss']
})
export class PartySelectorComponent implements OnInit {

  credentialStartType: any = "default";
  imgURL = environment.imgURL + 'scc2023/';

  constructor(private router: Router,
    private localDataStorageService: LocalDataStorageService,
    private activatedRoute: ActivatedRoute) { 
      this.activatedRoute.params.subscribe(params => 
        this.credentialStartType = params['credentialStartType']);
    }

  ngOnInit(): void {
  }

  onClick(party: string):void {
    this.localDataStorageService.setData("currentParty", party);
    this.router.navigate(["/besuchernachweis/" + party]);
  }

  onClickBack() {
    this.router.navigate(['home']);
  }
}
